/** @format */

@import "fonts";
@import "bootstrap";
@import "helpers";
@import "~react-notifications-component/dist/theme.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
}

h1,
h2 {
  font-size: 25px;
  color: #2d2c2c;
  margin-bottom: 30px;
  font-weight: bold;
}

.input-search,
.input-text,
.input-select {
  background: #ffffff;
  color: #777676;
  border: 1px solid #707070;
  margin-bottom: 30px;
  outline: none;
  padding: 0 10px;
  font-size: 12px;
  width: 100%;
  height: 38px;
  overflow: hidden;
  border-radius: 5px;
}

.form-search {
  position: relative;

  .input-search {
    padding-left: 35px;
  }

  i {
    cursor: pointer;
    position: absolute;
    top: 12px;
    left: 10px;
    color: #004c94;
    transition: color 300ms linear;
    &:hover {
      color: #ffef00;
    }
  }
}

.btn {
  display: block;
  width: 100%;
  height: 43px;
  line-height: 43px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  transition: all 300ms linear;
  background: none;
  border: 1px solid transparent;
  color: #7d7d7d;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;

  &:visited,
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }

  &.btn-white-yellow {
    border-color: #7d7d7d;
    color: #7d7d7d;
    background-color: #ffffff;

    &:hover {
      color: #004c94;
      border-color: #ffef00;
      background-color: #ffef00;
      text-decoration: none;
    }
  }

  &.btn-default {
    border-color: #3d3d3d;
    color: #004c94;
    background-color: #ffffff;

    &.active,
    &:hover {
      color: #ffffff;
      border-color: #004c94;
      background-color: #004c94;
      text-decoration: none;
    }
    &.active:hover {
      color: #004c94;
      border-color: #ffef00;
      background-color: #ffef00;
    }
  }

  &.btn-primary {
    color: #ffffff;
    border-color: #004c94;
    background-color: #004c94;

    &:hover {
      color: #004c94;
      border-color: #ffef00;
      background-color: #ffef00;
      text-decoration: none;
    }
    &:disabled,
    &.disabled {
      cursor: not-allowed;
      color: #dedede;
      border-color: #bbbbbb;
      background-color: #bbbbbb;
    }
  }

  &.btn-secondary {
    border-color: #dcdcdc;
    color: #707070;
    background-color: #dcdcdc;

    i {
      color: #004c94;
      margin-right: 5px;
    }

    &:hover {
      &,
      i {
        color: #ffffff;
      }
      border-color: #004c94;
      background-color: #004c94;
    }
  }
}

.slick-prev,
.slick-next {
  z-index: 1;
}

.img-post {
  display: block;
  margin: 10px 0;
  width: 100%;
}

.fa-file-image {
  color: #fa1212;
}

.fa-file-video {
  color: #1c9c24;
}

.fa-file-pdf {
  color: #fa8212;
}

.fa-trophy-alt {
  color: #ffa100;
  font-size: 30px;
}

.fa-medal {
  color: #0f4dbc;
  font-size: 30px;
}

.line-course-profile {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background: #e6e6e6;
    bottom: 0;
  }
}

.slider-home {
  .slick-next,
  .slick-prev {
    z-index: 1 !important;
  }

  .slick-next {
    margin-right: 15px;
  }

  .slick-prev {
    margin-left: 15px;
  }

  .slick-next:before {
    content: "\f054" !important;
    font-family: "Font Awesome 5 Pro" !important;
    font-size: 33px !important;
    text-shadow: 1px 1px 1px #a9a9a9;
  }

  .slick-prev:before {
    content: "\f053" !important;
    font-family: "Font Awesome 5 Pro" !important;
    font-size: 30px !important;
    text-shadow: 1px 1px 1px #a9a9a9;
  }

  .slick-dots {
    bottom: 5px !important;
  }

  .slick-dots li {
    width: auto;
  }

  .slick-dots li button:before {
    font-size: 12px !important;
    color: #fff !important;
  }
}

.profile-progress-box {
  .fa-chevron-down {
    font-size: 18px;
    padding: 20px;
    cursor: pointer;
    transition: ease all 300ms;
  }

  .profile-courses {
    height: 0;
    margin: 2px;
    transition: all 300ms linear;
  }

  &.open {
    .fa-chevron-down {
      transform: rotate(180deg);
    }

    .profile-courses {
      height: 100%;
    }
  }

  @media (max-width: 991px) {
    .fa-chevron-down {
      padding: 0;
    }
  }
}

@keyframes open {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

.hidemenu {
  transform: translateY(-100%);
}

.search-buttom {
  background-color: transparent;
  @media (max-width: 991px) {
    color: #fff;
  }
}

.btn-logout {
  background: transparent;
  text-align: left;
  padding: 6px 10px;

  &:hover {
    color: #ffa100;
  }
}

button.burger {
  display: none;
  @media (max-width: 991px) {
    background: transparent;
    display: flex;
    height: auto;
    font-size: 20px;
    height: 45px;
    width: 45px;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin: 0 5px;
    outline: none;
  }
}

div.menu-open {
  height: auto !important;
}

button.menu-open {
  background-color: #eee !important;
}

.name-area {
  > a {
    margin: 0;
    padding: 20px 5px;
    color: #222;

    @media (max-width: 991px) {
      color: #fff;
      padding: 10px 5px;
    }
  }

  .dropdown-area {
    position: relative;

    h3 {
      padding: 20px 0;
      height: 58px;
      margin: 0;
      font-size: 13px;
      font-weight: normal;
      line-height: 1;
      display: flex;
      align-items: center;
      padding: 20px 0;
      height: 58px;

      @media (max-width: 991px) {
        color: #fff;
      }
    }

    nav {
      display: none;
      position: absolute;
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 8px;
      width: 130%;
      left: -25px;
      padding: 0;
      flex-direction: column;
      margin: 0 5px 10px;

      @media (max-width: 991px) {
        position: relative;
      }

      a {
        text-align: left;
        padding: 6px 10px;
        color: #222;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &:hover {
      nav {
        display: flex !important;
      }
    }
  }
}

.progress-indicator {
  margin: 0;
}

.presencial-insdisponivel {
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  background-color: transparent;
  box-shadow: none;

  &:hover {
    border-style: solid;
    border-width: 1px;
    border-color: hsla(0, 0%, 86.7%, 0.82);
    background-color: transparent;
    box-shadow: none;
  }
}

.btn-exam {
  display: block;
  border-radius: 16px;
  background-color: #ffa100;
  color: #000;
  border: solid 1px #ffa100;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  width: fit-content;
  padding: 9px 38px;
  margin: 20px 0;
  text-decoration: none;
  transition: ease all 500ms;

  &:hover {
    background-color: #ffbb00;
    border: solid 1px #ffbb00;
    transform: translate(0px, -5px);
    color: #000;
    text-decoration: none;
  }
}

.modal {
  opacity: 1 !important;
}

.exam-slider {
  .content-questions {
    margin-bottom: 55px;
  }

  .slick-next:before {
    content: "" !important;
  }

  .slick-prev:before {
    content: "" !important;
  }
}

.modal-dialog {
  @media (min-width: 576px) {
    max-width: 90% !important;
  }

  @media (min-width: 992px) {
    max-width: 1100px !important;
  }
}

.close-modal {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 20px;
  font-weight: bolder;
}

.slick-disabled {
  display: none !important;
}

.parent-acordeon {
  .acordeon {
    transition: height 300ms;
    overflow: hidden;
    height: auto;
  }

  &:hover {
    .acordeon {
      height: auto;
    }
  }
}

.alt-closed {
  height: 0;
}

.alt-open {
  height: auto;
}

.modal-exam {
  .modal-content {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;

    .close-nota-modal {
      position: fixed;
      margin: 10px 15px;
      top: 0;
      right: 0;
      font-size: 45px;
      font-weight: lighter;
      color: #fff;
      cursor: pointer;
    }

    .success-modal {
      h1 {
        margin: -6px 0 10px;
        font-size: 38px;
        text-align: center;
        z-index: 999;
      }

      p {
        font-size: 14px;
        text-align: center;
        z-index: 999;
      }
    }

    .fail-modal {
      h1 {
        margin: 10px 0 0;
        text-align: center;
        font-size: 38px;
        z-index: 999;
      }

      p {
        text-align: center;
        font-size: 14px;
        margin: 0;
        z-index: 999;
      }
    }
  }
}

.modal-curso-presencial {
  .modal-content {
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .close-classroom-modal {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 18px;
      margin: 8px 10px;
      cursor: pointer;
    }

    p {
      margin: 0;
      font-size: 18px;
      padding: 50px 0 30px;
    }
  }
}

.exam-area {
  background-color: #00985f;
  padding: 30px 0;

  .course-exame {
    h2 {
      margin: 0;
      color: #fff;
      font-size: 35px;
    }

    p {
      padding-top: 12px;
      margin: 0;
      color: #fff;
      font-size: 14px;
      text-align: left;
    }
  }
}

.form-contato {
  label {
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
  }

  .contato-input {
    min-height: 85px;

    input {
      padding: 10px 20px;
      border-style: solid;
      border-width: 1px;
      border-color: #c2bebe;
      border-radius: 50px;
      background-color: transparent;
      color: #333;
      font-size: 14px;
    }
  }
  .contato-text-area {
    min-height: 85px;

    textarea {
      padding: 10px 20px;
      border-style: solid;
      border-width: 1px;
      border-color: #c2bebe;
      border-radius: 22px;
      background-color: transparent;
      color: #333;
      font-size: 14px;
    }
  }
  button[type="submit"] {
    background-color: #ffa100;
    transform: translate(0px, -5px);
    color: #000;
    text-decoration: none;
    padding: 10px 35px;
    border-radius: 16px;
    font-size: 14px;

    &:disabled {
      background-color: #ffce79;
      color: #898989;
    }
  }
}

.contato-file {
  .header {
    display: none;
  }

  > section {
    display: none;
  }

  > section:first-child {
    display: block;
  }

  h2 {
    display: none;
  }
}

.btn-upload-file {
  background: none;
  padding-left: 25px;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: transparent;
  width: 120px;

  input {
    opacity: 0 !important;
  }
}

.btn-upload-file::before {
  content: "\f03d";
  font-family: "Font Awesome 5 Pro" !important;
  font-size: 18px !important;
  position: absolute;
  top: 0;
  left: 2px;
  color: #333;
  font-weight: 600;
}

.btn-upload-file::after {
  content: "Anexar vídeo";
  position: absolute;
  top: 4px;
  right: 0;
  color: #333;
  z-index: -1;
  text-decoration: underline;
}

.infosUploader {
  .info {
    div,
    p {
      margin: 0;
    }
    .size {
      display: none;
    }
    .loadingInfo + p {
      display: none;
    }
  }
}

.medias {
  // height: auto;
  // overflow: hidden;
  // // padding-bottom: 56.25%;
  position: relative;
}

.medias iframe {
  // position: absolute;
  // height: 100%;
  // width: 100%;
  border: none;
}

.medias img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.search {
  display: flex;
  align-items: center;
  margin-right: 10px;
  background-color: 0;

  @media (max-width: 991.98px) {
    margin-right: 0;
  }

  .search-form {
    display: flex;
    position: relative;
    right: 0;
    top: 0;

    input {
      width: 180px;
      margin-bottom: 0px;
      padding: 5px 30px 5px 10px;
      border: none;
      border-radius: 20px;
      background-color: #fff;
      font-size: 12px;
      display: block;
      width: 0;
      outline: none;
      transition: ease all 200ms;

      @media (max-width: 991.98px) {
        width: 170px !important;
        border: 1px solid #ccc;
      }
    }

    button {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      background: #fff;
      outline: none;
    }
  }
}

.search.active {
  .search-form {
    input {
      width: 160px !important;
      border: 1px solid #ccc;
    }
  }
}

.card-prog-lock {
  position: relative;

  &:before {
    content: "";
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
  }
}

.rank-position {
  font-size: 23px;
  color: #949494;
  justify-content: center;
}

.rank-first {
  background: #edbc53 !important;

  div {
    display: flex;
    align-items: center;
    color: #616161;
    font-weight: 600;
  }
}

.rank-second {
  background: #c0bcb9 !important;

  div {
    display: flex;
    align-items: center;
    color: #616161;
    font-weight: 600;
  }
}

.rank-third {
  background: #e2a772 !important;

  div {
    display: flex;
    align-items: center;
    color: #616161;
    font-weight: 600;
  }
}

.rank-name {
  color: #616161;
  font-size: 14px;
}

.rank-score {
  color: #616161;
  font-size: 14px;
  font-weight: 600;
}

.rank-medal {
  justify-content: flex-end;
  i {
    color: #949494;
    font-size: 20px;
  }

  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.categorias {
  margin-top: -1px;
  padding: 55px 0;
  background-color: transparent;
  color: #000;
  max-width: 95vw;
  margin: auto;

  @media screen and (max-width: 768px) {
    padding: 30px 0 15px;
  }

  a:hover {
    text-decoration: none;
  }
}

.category-card {
  height: 300px;
  padding: 40px 38px;
  border: 1px solid transparent;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 2px 13px -8px #646262;
  transition: box-shadow 400ms ease, transform 400ms ease;
  text-align: left;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding: 15px 5px;
    height: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 20px 0px;
    color: #333;
    font-size: 23px;
    line-height: 1;
    font-weight: 700;

    @media screen and (max-width: 768px) {
      font-size: 12px;
      text-align: center;
      min-height: 80px;
      display: flex;
      align-items: center;
    }
  }
}

.forum-frame {
  width: 100%;
  height: 600px;
  border: none;
}

.title-category {
  position: relative;

  .category-filter {
    span {
      display: block;
      width: fit-content;
      text-align: right;
      margin-bottom: 10px;
      cursor: pointer;
    }

    ul {
      position: absolute;
      list-style: none;
      right: 15px;
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 0;
      z-index: 10;
      width: fit-content;
      display: none;

      li {
        padding: 10px;
        cursor: pointer;
      }

      &.open {
        display: block;
      }
    }

    i {
      font-size: 10px;
    }
  }
}

.pointer {
  cursor: pointer;
}

// .authenticated-border {
//   border: 1.2px solid #00985f !important;
// }

// .rank-name.authenticated {
//   color: #00985f !important;
//   font-weight: 600;
// }

.menu-active {
  color: #ffa100 !important;
}

iframe#contentFrame {
  border: none;
}

.scorm-frame {
  width: 100%;
  height: 850px !important;
}

.file-view:before {
  content: "CLIQUE PRA VER O CONTEÚDO";
  position: absolute;
  display: flex;
  z-index: 999;
  background: #000000bd;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: #fff;
}

button.buton-refresh {
  position: absolute;
  color: #000000;
  background: #ffa100;
  bottom: 0;
  z-index: 999;
  right: 0;
  font-weight: 700;
  font-size: 12px;
  padding: 5px;
  outline: none;

  &.watched {
    background-color: #00985f;
    color: #fff;
    cursor: initial;
  }
}

.video-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slider-course {
  .slick-next:before {
    content: "\f054" !important;
    font-family: "Font Awesome 5 Pro" !important;
    font-size: 33px !important;
    text-shadow: 1px 1px 1px #a9a9a9;
    color: #2e2e2e;
  }

  .slick-prev:before {
    content: "\f053" !important;
    font-family: "Font Awesome 5 Pro" !important;
    font-size: 30px !important;
    text-shadow: 1px 1px 1px #a9a9a9;
    color: #2e2e2e;
  }

  .slick-next {
    right: -15px;
  }

  .slick-prev {
    left: -15px;
  }
}

.course-slide-item {
  padding: 5px 15px;
}

.slick-track {
  min-width: 100% !important;
}

.content-file {
  padding-bottom: 60%;
}

.content-file iframe {
  height: 93%;
  position: absolute;
  width: 100%;
}

.modal-blockled-home {
  width: 750px;

  @media (max-width: 991px) {
    width: auto;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;

    p {
      padding: 0;
      margin: 0;
      text-align: center;
    }

    form {
      display: flex;
      margin-top: 15px;
      width: 400px;
      max-width: 90%;

      @media (max-width: 991px) {
        flex-direction: column;
      }

      input {
        padding: 0 5px;
        width: 100%;
      }

      button {
        background: #00985f;
        color: #fff;
        margin-left: 5px;

        @media (max-width: 991px) {
          margin: 5px 0 0;
          width: 100%;
        }
      }
    }
  }
}

span.email-message {
  color: #ff1700;
  margin: 5px 0 0;
}

.next-lesson-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 20px;
  color: #fff;
  font-size: 80px;
  transform: translateY(-50%);
  cursor: pointer;
  text-shadow: 0px 0px 10px #5f5f5f;
  @media (max-width: 991px) {
    font-size: 50px;
  }
}

.mobile-pdf {
  display: none;
  @media (max-width: 768px) {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56%;

    a {
      position: absolute;
      color: #000000;
      background: #ffa100;
      min-width: 50%;
      height: 40px;
      font-weight: bolder;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
      z-index: -2;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.45);
      z-index: -1;
    }
  }
}

.pdf-frame {
  @media (max-width: 768px) {
    display: none;
  }
}

.embed-responsive {
  height: calc(100vh - 160px);
  @media (max-width: 768px) {
    height: calc(100vh - 114px);
  }
}
