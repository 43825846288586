.w-embed-youtubevideo {
  width: 100%;
  position: relative;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/youtube-placeholder.2b05e7d68d.svg');
  background-size: cover;
  background-position: 50% 50%;
}

.w-embed-youtubevideo:empty {
  min-height: 75px;
  padding-bottom: 56.25%;
}

.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
}

.menu2 {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  height: auto;
  margin-top: 0px;
  background-color: #fff;
  box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.51);
}

.nav-menu {
  left: 52px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Montserrat, sans-serif;
  text-align: left;
}

.container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
}



.div-block-2 {
  height: 300px;
  padding: 55px 38px;
  border: 1px solid transparent;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 2px 13px -8px #646262;
  -webkit-transition: box-shadow 400ms ease, -webkit-transform 400ms ease;
  transition: box-shadow 400ms ease, -webkit-transform 400ms ease;
  transition: box-shadow 400ms ease, transform 400ms ease;
  transition: box-shadow 400ms ease, transform 400ms ease, -webkit-transform 400ms ease;
  text-align: left;
  text-decoration: none;
}

.div-block-2:hover {
  border: 1px solid transparent;
  box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
  -webkit-transform: translate(0px, -6px) scale(1.02);
  -ms-transform: translate(0px, -6px) scale(1.02);
  transform: translate(0px, -6px) scale(1.02);
}

.heading {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 32px 0px 20px;
  color: #333;
  font-size: 23px;
  line-height: 30px;
  font-weight: 700;
}

.text-block {
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #ffa100;
  font-size: 40px;
}

.text-block._1 {
  font-size: 35px;
}

.body {
  font-family: Montserrat, sans-serif;
}

.heading-2 {
  margin-top: 0px;
  padding-left: 0px;
  color: #333;
  font-size: 40px;
  line-height: 44px;
  font-weight: 700;
}

.video {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 200px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 10%;
  background-size: auto, cover;
  line-height: 20px;
}

.recomendados {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 25px;
  padding-bottom: 35px;
  background-color: hsla(0, 0%, 97.3%, 0.78);
}

.text-block-2 {
  color: red;
  font-size: 10px;
  font-weight: 600;
  text-decoration: underline;
  text-transform: uppercase;
}

.texto-interna {
  padding-top: 3px;
  padding-bottom: 7px;
  color: #333;
  font-size: 17px;
  line-height: 25px;
  font-weight: 700;
  text-decoration: none;
  text-transform: none;
}

.text-block-4 {
  padding-bottom: 0px;
  color: #aca3a3;
  font-size: 14px;
  text-align: right;
  text-decoration: none;
}

.div-interna {
  position: static;
  left: 7%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 21px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #fff;
  text-decoration: none;
}

.columns {
  margin-top: 0px;
  margin-bottom: 46px;
}

.div-block-5 {
  width: 100px;
  height: 1px;
  margin-left: -42px;
  padding-right: 0px;
  padding-left: 62px;
  background-color: #ffa100;
  color: #fadd12;
}

.titulo {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 30px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.titulo.segunda {
  margin-bottom: 0px;
}

.titulo._2 {
  margin-bottom: 0px;
}

.btn {
  margin-right: 0%;
  margin-left: 0%;
  padding-right: 38px;
  padding-left: 38px;
  border: 1px solid #00985f;
  border-radius: 16px;
  background-color: #00985f;
  color: #fff;
  font-weight: 500;
}

.div-block-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.div-block-11 {
  margin-top: 81px;
  margin-right: 0px;
  margin-bottom: -10px;
  text-align: right;
  text-decoration: none;
}

.mais-assistidos {
  margin-top: 20px;
}

.video-copy {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 120px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 100%;
  background-size: auto, cover;
  line-height: 20px;
}

.columns-2 {
  margin-top: 0px;
  margin-bottom: 46px;
}

.linha1 {
  width: 100px;
  height: 1px;
  margin-left: -51px;
  padding-right: 0px;
  padding-left: 83px;
  background-color: #ffa100;
  color: #fadd12;
}

.rodap-_2 {
  position: static;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  margin-top: 80px;
  padding-top: 18px;
  padding-bottom: 18px;
  background-color: #191919;
  color: #fff;
}

.rodap-_2.oculto {
  display: block;
}

.container-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image {
  width: 110px;
}

.heading-3 {
  margin-top: 0px;
  padding-right: 540px;
  color: #333;
  font-size: 30px;
}

.div-block-14 {
  display: block;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.live {
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #000;
  text-align: center;
}

.text-block-5 {
  padding-top: 3px;
  padding-left: 10px;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
}

.text-block-6 {
  display: block;
  padding-top: 3px;
  font-family: 'Font awesome 5 pro solid 900', sans-serif;
  color: #08ff00;
  font-size: 10px;
}

.container-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.btn2 {
  display: none;
  margin-top: 18px;
  padding-right: 38px;
  padding-left: 38px;
  border-style: solid;
  border-width: 1px;
  border-color: #fadd12;
  border-radius: 8px;
  background-color: transparent;
  color: #fadd12;
  font-weight: 500;
}

.cursos {
  margin-top: 50px;
  color: #333;
}

.cursos:hover {
  color: #ffa100;
}

.cursos:visited {
  background-color: transparent;
  color: #333;
}

.cursos.w--current {
  color: #ffa100;
}

.cursos.w--current:focus {
  color: #ffa100;
}

.pesquisa-cursos {
  margin-top: 35px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  text-align: left;
}

.columns-3 {
  margin-top: 40px;
}

.btn3 {
  display: block;
  padding-right: 38px;
  padding-left: 38px;
  border: 1px solid transparent;
  border-radius: 16px;
  background-color: #00985f;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  color: #fff;
  font-weight: 500;
  text-align: center;
}

.btn3:hover {
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
}

.div-block-16 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.banner2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 300px;
  margin-top: 80px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  background-position: 50% 30%;
  background-size: cover;
}

.container-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.textoanual {
  width: auto;
  padding-right: 0px;
  padding-left: 0px;
  color: #616161;
  font-size: 12px;
  text-align: left;
  text-decoration: none;
}

.paragraph-2 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #616161;
}

.paragraph-3 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #616161;
}

.camila2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.camila-icon {
  position: static;
  left: 62px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0px;
  padding: 0px 10px 0px 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  text-decoration: none;
}

.camila-icon:hover {
  color: #ffa100;
}

.videointerna {
  margin-top: 130px;
}

.div-block-17 {
  height: 400px;
  background-color: transparent;
}

.div-block-18 {
  height: 400px;
}

.div-block-19 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 400px;
  margin-right: 15px;
  margin-left: 15px;
  padding: 23px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 6px 13px -8px #6d6b6b;
}

.numero {
  padding-bottom: 0px;
  font-family: Montserrat, sans-serif;
  color: #ffa100;
  font-size: 35px;
  font-weight: 700;
}

.body-2 {
  border: 1px solid transparent;
  font-family: Montserrat, sans-serif;
}

.auulas {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 0px;
  color: #616161;
}

.linhamenu {
  width: 150px;
  height: 1px;
  margin-top: 0px;
  background-color: #f0f0f0;
  color: #b4a9a9;
}

.aulas {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.curtir {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.icon {
  padding-bottom: 0px;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #ffa100;
  font-size: 35px;
  font-weight: 700;
}

.curtiir {
  margin-top: 20px;
  margin-bottom: 15px;
  padding-top: 0px;
  color: #616161;
}

.favorito {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.icon-2 {
  padding-bottom: 0px;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #ffa100;
  font-size: 35px;
  font-weight: 700;
}

.curtiir2 {
  margin-top: 20px;
  margin-bottom: 15px;
  padding-top: 0px;
  color: #616161;
}

.material-de-apoio {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 43px;
  background-color: transparent;
}

.blocapoio1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  height: 80px;
  margin-right: 20px;
  padding: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
  -webkit-transition: border 400ms ease, -webkit-transform 400ms ease;
  transition: border 400ms ease, -webkit-transform 400ms ease;
  transition: transform 400ms ease, border 400ms ease;
  transition: transform 400ms ease, border 400ms ease, -webkit-transform 400ms ease;
  text-decoration: none;
}

.blocapoio1:hover {
  border: 1px solid #ddd;
  box-shadow: none;
  -webkit-transform: scale(1) translate(0px, 6px);
  -ms-transform: scale(1) translate(0px, 6px);
  transform: scale(1) translate(0px, 6px);
}

.text-block-9 {
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #fa8212;
  font-size: 30px;
}

.text-block-10 {
  margin-top: 0px;
  padding-top: 0px;
  padding-left: 20px;
  color: #000;
  font-size: 12px;
  text-align: left;
}

.img {
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #1c9c24;
  font-size: 30px;
}

.pdf {
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #fa1212;
  font-size: 30px;
}

.outros-videos {
  margin-top: 0px;
  margin-bottom: 50px;
}

.linha {
  width: 730px;
  height: 1px;
  margin: 0px auto -15px;
  padding-top: 0px;
  border-style: solid;
  border-width: 0px;
  border-color: #fff;
  background-color: #ddd;
  color: #ffa100;
}

.text-block-11 {
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #ffa100;
  font-size: 16px;
  text-align: center;
}

.div-block-23 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #fff;
  color: #fff;
}

.column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.column-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.column-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.column-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.videos3 {
  margin-top: 20px;
}

.formulario {
  display: block;
  width: auto;
  margin-top: 79px;
  margin-bottom: 0px;
  padding-top: 58px;
  padding-bottom: 58px;
  background-color: transparent;
}

.text-field {
  width: 350px;
  margin-right: 37px;
  margin-bottom: 0px;
  padding: 10px 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #c2bebe;
  border-radius: 50px;
  background-color: transparent;
  color: #333;
}

.text-field-2 {
  width: 350px;
  padding-right: 20px;
  padding-left: 20px;
  border: 1px solid #c2bebe;
  border-radius: 50px;
  background-color: transparent;
}

.div-block-24 {
  margin-top: 60px;
  margin-bottom: 30px;
}

.paragraph-4 {
  margin-top: 20px;
  margin-bottom: 0px;
}

.link-block {
  text-decoration: none;
}

.link-block-2 {
  text-decoration: none;
}

.icontest {
  margin-bottom: 0px;
  font-family: 'Font awesome 5 pro solid 900', sans-serif;
  color: #333;
  font-size: 14px;
}

.div-block-25 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.linhamenu2 {
  display: none;
  width: 100vw;
  height: 1px;
  margin-top: 0px;
  background-color: #f0f0f0;
  color: #b4a9a9;
}

.heading-5 {
  font-size: 35px;
}

.div-block-26 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  height: 50px;
  margin-right: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: transparent;
  -webkit-transition: none 0ms ease;
  transition: none 0ms ease;
  text-align: left;
}

.div-block-26:hover {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.div-block-27 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.field-label {
  margin-bottom: 5px;
  color: #333;
}

.field-label-2 {
  margin-bottom: 5px;
  padding-right: 0px;
  color: #333;
}

.caixamsg {
  width: 740px;
  height: 80px;
  margin-bottom: 0px;
  padding: 0px 20px 39px;
  border: 1px solid #c2bebe;
  border-radius: 20px;
  background-color: transparent;
}

.div-block-28 {
  margin-top: 30px;
  margin-bottom: 0px;
}

.div-block-30 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.field-label-3 {
  color: #333;
}

.cursosvideos {
  width: auto;
  margin-top: 0px;
  margin-bottom: 30px;
  color: #333;
  font-size: 25px;
  font-weight: 600;
}

.trilhasmenu:hover {
  color: #ffa100;
}

.trilhasmenu.w--current {
  color: #ffa100;
}

.menulive:hover {
  color: #ffa100;
}

.menulive.w--current {
  color: #ffa100;
}

.forum:hover {
  color: #ffa100;
}

.forum.w--current {
  color: #ffa100;
}

.bold-text {
  color: #f8f8f8;
  font-size: 30px;
}

.container-6 {
  margin-bottom: 15px;
  text-align: center;
}

.column-20 {
  border: 1px solid transparent;
}

.cardhome1 {
  position: static;
  padding: 0px;
  border: 0px solid transparent;
  border-radius: 16px;
  box-shadow: 0 2px 13px -8px #6d6b6b;
  -webkit-transition: box-shadow 400ms ease, -webkit-transform 400ms ease;
  transition: box-shadow 400ms ease, -webkit-transform 400ms ease;
  transition: box-shadow 400ms ease, transform 400ms ease;
  transition: box-shadow 400ms ease, transform 400ms ease, -webkit-transform 400ms ease;
}

.cardhome1:hover {
  border-color: transparent;
  box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
  -webkit-transform: scale(1.02) translate(0px, -6px);
  -ms-transform: scale(1.02) translate(0px, -6px);
  transform: scale(1.02) translate(0px, -6px);
}

.cardhome2 {
  position: static;
  padding: 0px;
  border: 1px solid transparent;
  border-radius: 16px;
  box-shadow: 0 2px 13px -8px #6d6b6b;
  -webkit-transition: box-shadow 400ms ease, -webkit-transform 400ms ease;
  transition: box-shadow 400ms ease, -webkit-transform 400ms ease;
  transition: transform 400ms ease, box-shadow 400ms ease;
  transition: transform 400ms ease, box-shadow 400ms ease, -webkit-transform 400ms ease;
  text-decoration: none;
}

.cardhome2:hover {
  border-color: #ddd;
  box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
  -webkit-transform: scale(1.02) translate(0px, -6px);
  -ms-transform: scale(1.02) translate(0px, -6px);
  transform: scale(1.02) translate(0px, -6px);
}

.cardcurso {
  position: static;
  padding: 0px;
  border: 0px solid transparent;
  border-radius: 16px;
  box-shadow: 0 2px 13px -8px #6d6b6b;
  -webkit-transition: box-shadow 400ms ease, -webkit-transform 400ms ease;
  transition: box-shadow 400ms ease, -webkit-transform 400ms ease;
  transition: box-shadow 400ms ease, transform 400ms ease;
  transition: box-shadow 400ms ease, transform 400ms ease, -webkit-transform 400ms ease;
  text-decoration: none;
}

.cardcurso:hover {
  border-width: 0px;
  border-color: transparent;
  box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
  opacity: 1;
  -webkit-transform: scale(1.02) translate(0px, -6px);
  -ms-transform: scale(1.02) translate(0px, -6px);
  transform: scale(1.02) translate(0px, -6px);
}

.div-block-31 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.curso {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  height: 120px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 10%;
  background-size: auto, cover;
  line-height: 20px;
}

._222 {
  position: static;
  left: 7%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 21px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #fff;
}

.div-block-32 {
  width: auto;
  height: auto;
  padding: 0px;
  border-radius: 8px;
  color: transparent;
  text-align: center;
}

.body-3 {
  font-family: Montserrat, sans-serif;
  color: #00985f;
}

.text-block-12 {
  padding-top: 3px;
  padding-bottom: 10px;
  border-radius: 8px;
  background-color: transparent;
  color: #00985f;
  font-size: 35px;
  font-weight: 500;
}

.text-block-13 {
  margin-top: 0px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: transparent;
  color: #616161;
}

.column-23 {
  display: block;
  margin-top: 11px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.text-block-14 {
  margin-top: 0px;
  color: #333;
  font-size: 12px;
  line-height: 40px;
}

.text-block-15 {
  margin-right: 10%;
  padding-right: 0px;
  border: 1px solid #f8f8f8;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

.paragraph-5 {
  margin-right: 10%;
  margin-bottom: 0px;
  padding-top: 3px;
  padding-bottom: 0px;
  color: #616161;
  font-size: 12px;
}

.datas {
  margin-top: 130px;
}

.agenda1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 25px 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 10px 16px -8px rgba(0, 0, 0, 0.15);
  -webkit-transition: box-shadow 400ms ease, -webkit-transform 400ms ease;
  transition: box-shadow 400ms ease, -webkit-transform 400ms ease;
  transition: box-shadow 400ms ease, transform 400ms ease;
  transition: box-shadow 400ms ease, transform 400ms ease, -webkit-transform 400ms ease;
}

.agenda1:hover {
  border: 1px solid transparent;
  box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
  -webkit-transform: scale(1.02) translate(0px, -6px);
  -ms-transform: scale(1.02) translate(0px, -6px);
  transform: scale(1.02) translate(0px, -6px);
}

.agenda1.esgotado {
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  background-color: transparent;
  box-shadow: none;
}

.column-24 {
  padding-right: 0px;
}

.bt {
  height: auto;
  padding-right: 38px;
  padding-left: 38px;
  border: 1px solid transparent;
  border-radius: 16px;
  background-color: #ffa100;
  -webkit-transition: opacity 200ms ease, background-color 400ms ease, -webkit-transform 400ms ease;
  transition: opacity 200ms ease, background-color 400ms ease, -webkit-transform 400ms ease;
  transition: opacity 200ms ease, transform 400ms ease, background-color 400ms ease;
  transition: opacity 200ms ease, transform 400ms ease, background-color 400ms ease, -webkit-transform 400ms ease;
  color: #000;
  font-weight: 500;
}

.bt:hover {
  -webkit-transform: translate(0px, -6px);
  -ms-transform: translate(0px, -6px);
  transform: translate(0px, -6px);
}

.text-block-16 {
  color: #333;
  font-size: 12px;
}

.column-25 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.column-26 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.column-27 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.column-28 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.column-29 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.column-30 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #0e0e0e;
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
}

.section:hover {
  border: 1px solid transparent;
}

.text-field-3 {
  width: 100%;
  margin-bottom: 0px;
  padding: 30px 20px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  border-style: none;
  border-radius: 0px;
  background-color: #f5f5f5;
  color: #18181d;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-transform: none;
}

.text-field-3:hover {
  background-color: #f0f0f0;
}

.text-field-3:active {
  background-color: #ececec;
}

.text-field-3:focus {
  background-color: #ececec;
  color: #18181d;
}

.text-field-3.bottom-margin-field {
  width: 260px;
  height: 40px;
  margin-bottom: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 16px;
  background-color: #e6e6e6;
  color: #0e0e0e;
  font-size: 12px;
}

.success-message {
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 30px;
  border-bottom: 3px solid #12b878;
  background-color: rgba(18, 184, 120, 0.1);
  color: #12b878;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.error-message {
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  padding: 10px 20px;
  border-bottom: 3px solid #db4b68;
  background-color: rgba(219, 75, 104, 0.05);
  color: #db4b68;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.formlogin {
  display: block;
  width: auto;
  margin-left: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.button-small {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 18px 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 0px;
  background-color: #18181d;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.button-small:hover {
  background-color: #272731;
}

.button-small.form-centered-button {
  width: 264px;
  height: 40px;
  padding: 9px 14px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 16px;
  background-color: #ffa100;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  color: #333;
  font-size: 12px;
}

.button-small.form-centered-button:hover {
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
}

.login {
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.body-4 {
  background-color: #000;
  font-family: Montserrat, sans-serif;
}

.container-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.text-block-17 {
  margin-top: 0px;
  margin-bottom: 29px;
  color: #000;
}

.text-block-18 {
  margin-top: 12px;
  margin-right: 0px;
  color: #000;
  font-size: 12px;
  text-align: right;
  text-decoration: underline;
}

.div-block-37 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 400px;
  height: 500px;
  margin-right: 0px;
  margin-bottom: 0px;
  padding: 0px 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 0px 18px 18px 0px;
  background-color: #fff;
  text-align: center;
}

.cursopdf-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  height: 120px;
  padding: 17px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-color: #ffa100;
  line-height: 20px;
}

.pdftext {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-align: right;
}

.pdftext2 {
  margin-top: 0px;
  padding-bottom: 0px;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-align: right;
}

.iconplus-copy {
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #fff;
  font-size: 30px;
}

.prova {
  display: block;
  height: auto;
  margin-top: 50px;
  margin-bottom: -81px;
  padding-top: 35px;
  padding-bottom: 35px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #00985f;
}

.div-block-38 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 16px;
  background-color: transparent;
  box-shadow: none;
  color: #fff;
}

.container-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-20 {
  padding-top: 12px;
  padding-bottom: 0px;
  color: #fff;
  font-size: 14px;
  text-align: left;
}

.text-block-23 {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.column-31 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.columns-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 60px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-39 {
  width: 20%;
  height: 45%;
  padding: 66px;
  border-radius: 100px;
  background-color: transparent;
  background-position: 40% 60%;
  background-size: cover;
}

.text-block-24 {
  margin-top: 35px;
  font-size: 25px;
}

.body-5 {
  box-shadow: 1px 1px 8px 0 #d6d6d6;
  font-family: Montserrat, sans-serif;
}

.text-block-26 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.text-block-27 {
  margin-top: 15px;
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
}

.conquistas {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 138px;
  margin-top: 10px;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #d6d6d6;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: none;
  opacity: 1;
}

.conquistas:hover {
  border-color: transparent;
  box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.23);
}

.text-block-28 {
  margin-right: 0px;
  margin-left: 0px;
  padding-left: 0px;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #ffa100;
  font-size: 30px;
}

.div-block-41 {
  text-align: center;
}

.column-32 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 16px;
  box-shadow: none;
  text-align: center;
}

._1 {
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;
  border: 1px solid transparent;
}

.text-block-31 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
}

.text-block-33 {
  margin-top: 10px;
  color: #616161;
  font-size: 12px;
}

.div-block-42 {
  width: 6px;
  height: 200px;
  border-radius: 5px;
  background-color: #00985f;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#07c51a), to(#00985f));
  background-image: linear-gradient(180deg, #07c51a, #00985f);
  line-height: 30px;
}

.jan {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.column-33 {
  display: block;
  margin-top: 0px;
  padding-left: 23px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.fev {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.mar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.abr {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.maio {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.jun {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.dez {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.julho {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.ago {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.set {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.out {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.nov {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.jan1 {
  width: 6px;
  height: 100px;
  border-radius: 5px;
  background-color: #00985f;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#07c51a), to(#00985f));
  background-image: linear-gradient(180deg, #07c51a, #00985f);
  line-height: 30px;
}

.mar2 {
  width: 6px;
  height: 150px;
  border-radius: 5px;
  background-color: #00985f;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#07c51a), to(#00985f));
  background-image: linear-gradient(180deg, #07c51a, #00985f);
  line-height: 30px;
}

.abr3 {
  width: 6px;
  height: 180px;
  border-radius: 5px;
  background-color: #00985f;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#07c51a), to(#00985f));
  background-image: linear-gradient(180deg, #07c51a, #00985f);
  line-height: 30px;
}

.maio1 {
  width: 6px;
  height: 60px;
  border-radius: 5px;
  background-color: #00985f;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#07c51a), to(#00985f));
  background-image: linear-gradient(180deg, #07c51a, #00985f);
  line-height: 30px;
}

.jun1 {
  width: 6px;
  height: 90px;
  border-radius: 5px;
  background-color: #00985f;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#07c51a), to(#00985f));
  background-image: linear-gradient(180deg, #07c51a, #00985f);
  line-height: 30px;
}

.jul1 {
  width: 6px;
  height: 130px;
  border-radius: 5px;
  background-color: #00985f;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#07c51a), to(#00985f));
  background-image: linear-gradient(180deg, #07c51a, #00985f);
  line-height: 30px;
}

.ago1 {
  width: 6px;
  height: 160px;
  border-radius: 5px;
  background-color: #00985f;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#07c51a), to(#00985f));
  background-image: linear-gradient(180deg, #07c51a, #00985f);
  line-height: 30px;
}

.set1 {
  width: 6px;
  height: 95px;
  border-radius: 5px;
  background-color: #00985f;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#07c51a), to(#00985f));
  background-image: linear-gradient(180deg, #07c51a, #00985f);
  line-height: 30px;
}

.out1 {
  width: 6px;
  height: 160px;
  border-radius: 5px;
  background-color: #00985f;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#07c51a), to(#00985f));
  background-image: linear-gradient(180deg, #07c51a, #00985f);
  line-height: 30px;
}

.nov1 {
  width: 6px;
  height: 180px;
  border-radius: 5px;
  background-color: #00985f;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#07c51a), to(#00985f));
  background-image: linear-gradient(180deg, #07c51a, #00985f);
  line-height: 30px;
}

.dez1 {
  width: 6px;
  height: 150px;
  border-radius: 5px;
  background-color: #00985f;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#07c51a), to(#00985f));
  background-image: linear-gradient(180deg, #07c51a, #00985f);
  line-height: 30px;
}

.meses {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 4px;
  margin-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.atividades {
  margin-top: 53px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  text-align: center;
}

.provas-realizadas {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 180px;
  height: 90px;
  margin-top: 0px;
  margin-right: 19px;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: none;
  opacity: 1;
}

.tex {
  padding-top: 15px;
  padding-bottom: 15px;
  color: #333;
  font-size: 30px;
  font-weight: 600;
}

.tituloprov {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #616161;
  font-size: 12px;
  font-weight: 400;
}

.text-block-34 {
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.div-block-45 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.provas {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.bloco1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-left: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.container-13 {
  margin-top: 0px;
}

.outros-cursos {
  display: none;
  margin-top: 80px;
  padding: 0px;
  background-color: #fff;
}

.body-6 {
  font-family: Montserrat, sans-serif;
}

.div-block-47 {
  width: 100%;
  height: 1px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: #e6e6e6;
}

.div-block-49 {
  overflow: auto;
  padding: 20px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 1px 8px 0 #d6d6d6;
}

.text-block-36 {
  color: #858383;
  font-size: 12px;
}

.text-block-37 {
  color: #858383;
  font-size: 12px;
}

.text-block-38 {
  color: #858383;
  font-size: 12px;
}

.text-block-39 {
  color: #858383;
  font-size: 12px;
}

.linhacert {
  width: 100%;
  height: 1px;
  margin-top: 35px;
  margin-bottom: 35px;
  border-style: solid;
  border-width: 1px;
  border-color: #e6e6e6;
}

.texto-cert {
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 30px;
  padding-left: 30px;
  font-size: 14px;
  font-weight: 600;
}

.texto-cert:hover {
  text-decoration: underline;
}

.blococertificado {
  overflow: auto;
  padding: 35px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 1px 8px 0 #d6d6d6;
}

.text-block-40 {
  display: block;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #ffa100;
  font-size: 50px;
  text-align: center;
}

.bloco2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-left: 0px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.baixarbtn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 65px;
  height: auto;
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 8px;
  color: #616161;
  text-align: right;
  text-decoration: underline;
}

.baixarbtn:hover {
  color: #333;
}

.setabaixa {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 5px;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #858383;
  font-size: 14px;
}

.resultado-provas {
  display: none;
  margin-top: 80px;
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: #f8f8f8;
}

.dropdown-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
}

.dropdown-toggle:hover {
  color: #ffa100;
}

.dropdown-list {
  background-color: #fff;
}

.dropdown-list.w--open {
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 8px;
  background-color: #fff;
  color: #f8f8f8;
}

.div-block-50 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.text-block-41 {
  margin-right: 20px;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #00985f;
  font-size: 20px;
}

.text-block-43 {
  color: #333;
  font-weight: 600;
  text-align: right;
}

.div-block-51 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.linhanotas {
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #e6e6e6;
}

.div-block-52 {
  padding: 25px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 1px 1px 8px 0 #d6d6d6;
}

.text-block-44 {
  font-size: 12px;
}

.paragraph-1 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #616161;
}

.text-block-45 {
  color: #616161;
}

.premiosadquiridos {
  margin-top: 60px;
}

.text-block-46 {
  margin-top: 10px;
  color: #333;
  font-weight: 500;
}

.div-block-53 {
  padding-bottom: 155px;
  background-position: 50% 50%;
  background-size: cover;
}

.bloc1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 21px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid transparent;
  border-radius: 16px;
  box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
  -webkit-transition: border 400ms ease, -webkit-transform 400ms ease;
  transition: border 400ms ease, -webkit-transform 400ms ease;
  transition: transform 400ms ease, border 400ms ease;
  transition: transform 400ms ease, border 400ms ease, -webkit-transform 400ms ease;
  text-align: left;
}

.bloc1:hover {
  border-color: #ddd;
  box-shadow: none;
  -webkit-transform: translate(0px, 6px) scale(1);
  -ms-transform: translate(0px, 6px) scale(1);
  transform: translate(0px, 6px) scale(1);
}

.div-block-55 {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-radius: 16px;
  box-shadow: none;
}

.columns-8 {
  padding: 25px;
}

.text-block-47 {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.btnpremios {
  display: block;
  padding-right: 38px;
  padding-left: 38px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(51, 51, 51, 0.4);
  border-radius: 16px;
  background-color: transparent;
  color: #6e6e6e;
  font-weight: 500;
  text-align: center;
}

.provas-realizadas-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 180px;
  height: 90px;
  margin-top: 0px;
  margin-right: 19px;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: none;
  opacity: 1;
}

.videolive {
  height: auto;
  background-color: transparent;
}

.text-block-48 {
  margin-right: 20px;
  background-color: transparent;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #db951c;
  font-size: 20px;
  text-align: right;
}

.body-7 {
  font-family: Montserrat, sans-serif;
}

.text-block-49 {
  margin-left: 0px;
  padding: 11px;
  border-radius: 0px;
  background-color: transparent;
  color: #db951c;
  font-size: 23px;
}

.div-block-57 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-37 {
  display: block;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-58 {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: #333;
  background-position: 50% 0%;
  background-size: cover;
}

.column-38 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-50 {
  margin-left: 20px;
  color: #616161;
  font-size: 14px;
}

.colocado1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-right: -50px;
  margin-left: -10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #dadada;
  border-radius: 16px;
  box-shadow: none;
  -webkit-transition: box-shadow 400ms ease, -webkit-transform 400ms ease;
  transition: box-shadow 400ms ease, -webkit-transform 400ms ease;
  transition: box-shadow 400ms ease, transform 400ms ease;
  transition: box-shadow 400ms ease, transform 400ms ease, -webkit-transform 400ms ease;
}

.colocado1:hover {
  border-color: transparent;
  box-shadow: 4px 4px 16px -8px #6d6b6b;
  -webkit-transform: translate(0px, -6px) scale(1.02);
  -ms-transform: translate(0px, -6px) scale(1.02);
  transform: translate(0px, -6px) scale(1.02);
}

.column-39 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.text-block-51 {
  color: #616161;
  font-weight: 600;
}

.prata {
  margin-right: 20px;
  background-color: transparent;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #646666;
  font-size: 20px;
  text-align: right;
}

.colocado2 {
  margin-left: 0px;
  padding: 11px 6px 11px 11px;
  border-radius: 0px;
  background-color: transparent;
  color: #646666;
  font-size: 23px;
}

.bronze {
  margin-right: 20px;
  background-color: transparent;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #bd7005;
  font-size: 20px;
  text-align: right;
}

.colocado3 {
  margin-left: 0px;
  padding: 11px 6px 11px 11px;
  border-radius: 0px;
  background-color: transparent;
  color: #bd7005;
  font-size: 23px;
}

.colocado4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-right: -50px;
  margin-left: -10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #dadada;
  border-radius: 16px;
  box-shadow: none;
  -webkit-transition: box-shadow 400ms ease, -webkit-transform 400ms ease;
  transition: box-shadow 400ms ease, -webkit-transform 400ms ease;
  transition: box-shadow 400ms ease, transform 400ms ease;
  transition: box-shadow 400ms ease, transform 400ms ease, -webkit-transform 400ms ease;
}

.colocado4:hover {
  border-color: transparent;
  box-shadow: 4px 4px 16px -8px #6d6b6b;
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

.colocado44 {
  margin-left: 0px;
  padding: 11px 6px 11px 11px;
  border-radius: 0px;
  background-color: transparent;
  color: #949494;
  font-size: 23px;
}

.outros {
  margin-right: 20px;
  background-color: transparent;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #949494;
  font-size: 20px;
  text-align: right;
}

.camilafoto {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: #333;
  background-position: 50% 0%;
  background-size: cover;
}

.colocado22 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-right: -50px;
  margin-left: -10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1.2px solid #00985f;
  border-radius: 16px;
  background-color: hsla(0, 0%, 100%, 0.07);
  box-shadow: none;
  -webkit-transition: box-shadow 400ms ease, -webkit-transform 400ms ease;
  transition: box-shadow 400ms ease, -webkit-transform 400ms ease;
  transition: box-shadow 400ms ease, transform 400ms ease;
  transition: box-shadow 400ms ease, transform 400ms ease, -webkit-transform 400ms ease;
}

.colocado22:hover {
  box-shadow: 0 5px 16px -8px #6d6b6b;
  -webkit-transform: scale(1.02) translate(0px, -6px);
  -ms-transform: scale(1.02) translate(0px, -6px);
  transform: scale(1.02) translate(0px, -6px);
}

.link {
  color: #333;
  text-decoration: none;
}

.link-2 {
  color: #fff;
  text-decoration: none;
}

.div-block-59 {
  display: none;
  margin-top: 30px;
  margin-bottom: 0px;
  background-color: transparent;
  text-align: center;
}

.premios {
  margin-top: 130px;
  margin-bottom: 0px;
}

.columns-9 {
  margin-top: 20px;
}

.container-14 {
  margin-top: 0px;
}

.body-8 {
  font-family: Montserrat, sans-serif;
}

.text-block-52 {
  margin-top: 5px;
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 700;
}

.body-9 {
  font-family: Montserrat, sans-serif;
}

.div-block-60 {
  width: 100px;
  height: 5px;
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 0px;
  padding-left: 0px;
  border-radius: 20px;
  background-color: #00985f;
}

.div-block-61 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-62 {
  padding: 0px;
  border-radius: 16px;
  box-shadow: 0 2px 13px -8px #6d6b6b;
  -webkit-transition: box-shadow 400ms ease, -webkit-transform 400ms ease;
  transition: box-shadow 400ms ease, -webkit-transform 400ms ease;
  transition: box-shadow 400ms ease, transform 400ms ease;
  transition: box-shadow 400ms ease, transform 400ms ease, -webkit-transform 400ms ease;
}

.div-block-62:hover {
  border: 1px solid transparent;
  box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
  -webkit-transform: translate(0px, -6px) scale(1.02);
  -ms-transform: translate(0px, -6px) scale(1.02);
  transform: translate(0px, -6px) scale(1.02);
}

.text-block-53 {
  padding-right: 0px;
  color: #616161;
}

.div-block-63 {
  display: block;
  width: auto;
  height: auto;
  padding-bottom: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-position: 0px 0px, 50% 25%;
  background-size: auto, cover;
}

.div-block-64 {
  padding: 25px;
}

.text-block-54 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #616161;
}

.text-block-55 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #616161;
}

.text-block-56 {
  color: #616161;
}

.div-block-65 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  color: #f9461c;
  text-align: left;
}

.text-block-57 {
  color: #616161;
}

.btncertificado {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 65px;
  height: auto;
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 8px;
  color: #333;
  font-weight: 500;
  text-align: right;
  text-decoration: none;
}

.seta {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 6px;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: red;
  font-size: 14px;
}

.textcertificado {
  padding-right: 0px;
  padding-left: 0px;
  color: red;
  font-size: 14px;
  text-align: left;
  text-decoration: none;
}

.body-10 {
  font-family: Montserrat, sans-serif;
}

.div-block-67 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  padding-top: 85px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tempocertificado {
  margin-bottom: 0px;
  padding-bottom: 0px;
  color: #aca3a3;
  font-size: 14px;
  text-align: right;
}

.tempocertificado2 {
  margin-top: 53px;
  margin-right: 15px;
  margin-bottom: 0px;
  text-align: right;
}

.columns-10 {
  margin-top: 20px;
}

.dropdown-link {
  color: #333;
}

.dropdown-link:hover {
  color: #ffa100;
}

.dropdown-link.w--current {
  color: #333;
}

.dropdown-link.w--current:hover {
  color: #ffa100;
}

.dropdown-link-2 {
  border-radius: 8px;
  color: #333;
}

.dropdown-link-2:hover {
  color: #ffa100;
}

.btnhome {
  display: block;
  margin-bottom: 0px;
  padding-right: 38px;
  padding-left: 38px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #00985f;
  border-radius: 16px;
  background-color: transparent;
  -webkit-transition: color 500ms ease, background-color 500ms ease, -webkit-transform 500ms ease;
  transition: color 500ms ease, background-color 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, color 500ms ease, background-color 500ms ease;
  transition: transform 500ms ease, color 500ms ease, background-color 500ms ease, -webkit-transform 500ms ease;
  color: #00985f;
  font-weight: 500;
  text-align: center;
}

.btnhome:hover {
  background-color: #00985f;
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
  color: #fff;
}

.certificadoicon {
  display: block;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #ffa100;
  font-size: 35px;
  text-align: center;
}

.blocopdf {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 400px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ececec;
  background-position: 0px 0px, 50% 0%;
  background-size: auto, cover;
  color: #d3d1cf;
}

.text-block-58 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #fff;
  font-size: 50px;
  text-align: center;
}

.text-block-59 {
  margin-top: 40px;
  color: #fff;
  font-size: 14px;
}

.bold-text-2 {
  color: #ffa100;
  text-decoration: underline;
}

.btnconquistas {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  margin-top: 5px;
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 8px;
  text-align: left;
}

.conquistas2 {
  margin-right: 0px;
  margin-left: 0px;
  padding-left: 0px;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #0f4dbc;
  font-size: 30px;
}

.classifica-o2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 138px;
  margin-top: 10px;
  margin-left: 10px;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #d6d6d6;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: none;
  opacity: 1;
}

.classifica-o2:hover {
  border-color: transparent;
  box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.23);
}

.div-block-68 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.link-3 {
  color: #333;
  font-size: 12px;
}

.link-3:hover {
  color: #0f4dbc;
}

.btnclassifica-oi3 {
  width: 120px;
  padding-right: 0px;
  color: #333;
  font-size: 12px;
  text-align: center;
}

.btnclassifica-oi3:hover {
  color: #0f4dbc;
}

.column-40 {
  padding-right: 0px;
  padding-left: 0px;
}

.div-block-69 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 400px;
  height: 500px;
  padding-top: 0px;
  padding-bottom: 43px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
}

.column-41 {
  padding-right: 0px;
  padding-left: 0px;
}

.video2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 200px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 30%;
  background-size: auto, cover;
  line-height: 20px;
}

.video-3 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 200px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 20%;
  background-size: auto, cover;
  line-height: 20px;
}

.video4 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 120px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 30%;
  background-size: auto, cover;
  line-height: 20px;
}

.video5 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 120px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 10%;
  background-size: auto, cover;
  line-height: 20px;
}

.video-6 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 120px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 60%;
  background-size: auto, cover;
  line-height: 20px;
}

.link-4 {
  text-decoration: none;
}

.video7 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  height: 200px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 30%;
  background-size: auto, cover;
  line-height: 20px;
}

.video8 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  height: 200px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 30%;
  background-size: auto, cover;
  line-height: 20px;
}

.video9 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  height: 200px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 60%;
  background-size: auto, cover;
  line-height: 20px;
}

.video10 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  height: 200px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 30%;
  background-size: auto, cover;
  line-height: 20px;
}

.video11 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  height: 200px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 30%;
  background-size: auto, cover;
  line-height: 20px;
}

.video23 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  height: 200px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 30%;
  background-size: auto, cover;
  line-height: 20px;
}

.text-block-61 {
  margin-top: 10px;
  margin-bottom: 0px;
  color: #f8f8f8;
  font-size: 12px;
}

.btnhomebanner {
  height: auto;
  margin-top: 0px;
  padding-right: 38px;
  padding-left: 38px;
  border-style: solid;
  border-width: 1px;
  border-color: #ffa100;
  border-radius: 16px;
  background-color: transparent;
  -webkit-transition: color 500ms ease, background-color 500ms ease, -webkit-transform 500ms ease;
  transition: color 500ms ease, background-color 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, color 500ms ease, background-color 500ms ease;
  transition: transform 500ms ease, color 500ms ease, background-color 500ms ease, -webkit-transform 500ms ease;
  color: #ffa100;
  font-weight: 500;
  text-align: center;
}

.btnhomebanner:hover {
  background-color: #ffa100;
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
  color: #333;
}

.eu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-3 {
  display: none;
  margin-top: -2px;
  margin-bottom: 0px;
}

.column-38-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-62 {
  margin-top: -21px;
  margin-bottom: 5px;
  color: #fff;
  font-size: 14px;
}

.nomebold {
  margin-left: 20px;
  color: #00985f;
  font-size: 14px;
  font-weight: 700;
}

.container-18 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.texto-banner-2 {
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.textobannerhome {
  color: #fff;
  font-size: 48px;
  line-height: 50px;
  text-align: center;
}

.columns-11 {
  height: auto;
  border-radius: 18px;
  box-shadow: 0 3px 20px -1px #000;
}

.image-4 {
  width: 220px;
}

.btnlogin {
  height: auto;
  margin-top: 20px;
  padding-right: 38px;
  padding-left: 38px;
  border: 1px solid #fff;
  border-radius: 16px;
  background-color: transparent;
  -webkit-transition: color 500ms ease, background-color 500ms ease, -webkit-transform 500ms ease;
  transition: color 500ms ease, background-color 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, color 500ms ease, background-color 500ms ease;
  transition: transform 500ms ease, color 500ms ease, background-color 500ms ease, -webkit-transform 500ms ease;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.btnlogin:hover {
  border-color: transparent;
  background-color: #ffa100;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
  color: #333;
}

.div-block-72 {
  display: block;
  margin-top: 15px;
  margin-left: 102px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.textobanner2 {
  margin-top: 0px;
  padding-right: 540px;
  color: #333;
  font-size: 30px;
}

.container-19 {
  margin-top: 0px;
}

.titulo-2 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 50px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.titulo4 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.titulo-5 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 1px solid transparent;
}

.ranking1-copy {
  margin-top: 0px;
}

.titulo6 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 130px;
  margin-bottom: 0px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.div-interna-copy {
  position: static;
  left: 7%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 21px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #fff;
}

.search-button {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: none;
  margin-right: 5px;
  background-color: transparent;
  font-family: 'Font awesome 5 pro 400', sans-serif;
  color: #333;
}

.search-input {
  position: static;
  display: none;
  width: 250px;
  height: 35px;
  margin-bottom: 0px;
  padding-right: 22px;
  padding-left: 22px;
  border: 1px solid #333;
  border-radius: 20px;
  color: #0e0e0e;
  font-size: 12px;
}

.div-block-73 {
  position: relative;
}

.search {
  margin-bottom: 0px;
}

.div-block-74 {
  height: auto;
  margin-right: -33px;
  margin-left: -33px;
  padding: 40px 31px;
  border-radius: 16px;
  background-color: #f8f8f8;
}

.text1 {
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #ffa100;
  font-size: 16px;
  text-align: center;
}

.div-block-75 {
  display: block;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-76 {
  position: static;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
}

.categoriaspesquisar {
  margin-right: 0px;
  margin-left: 0px;
  font-size: 12px;
}

.dropdown-list-2 {
  background-color: #fff;
}

.dropdown-list-2.w--open {
  z-index: 10;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.dropdown-link-3 {
  font-size: 12px;
}

.dropdown-link-3:hover {
  color: #ffa100;
}

.dropdown-link-4 {
  font-size: 12px;
}

.dropdown-link-4:hover {
  color: #ffa100;
}

.dropdown-link-5 {
  font-size: 12px;
}

.text-block-65 {
  color: #333;
  font-size: 12px;
}

.icon-4 {
  color: #333;
}

.cardhome2-1 {
  position: static;
  padding: 0px;
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 0 6px 13px -8px #6d6b6b;
  text-decoration: none;
}

.cardhome2-1:hover {
  border-color: #ddd;
  box-shadow: none;
}

.text-block-67 {
  font-family: 'Font awesome 5 pro solid 900', sans-serif;
  color: #9b9b9b;
}

.body-11 {
  font-family: Montserrat, sans-serif;
}

.div-block-77 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #333;
}

.text-block-68 {
  margin-left: 15px;
}

.dropdown-toggle-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #ececec;
  color: #333;
}

.dropdown-toggle-2.w--open {
  margin-bottom: 0px;
}

.div-block-78 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  margin-left: 0px;
  padding-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #e99002;
  color: #a00e41;
  font-size: 10px;
}

.text-block-69 {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.text-block-70 {
  font-size: 18px;
  font-weight: 600;
}

.discurss-o1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  padding: 10px 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 16px;
  background-color: rgba(51, 51, 51, 0.04);
}

.div-block-80 {
  margin-right: 0px;
  margin-left: 20px;
}

.text-block-71 {
  width: 50px;
  height: auto;
  margin-right: 10px;
  padding: 0px;
  border-radius: 8px;
  background-color: #bebebe;
  color: #fff;
  font-size: 10px;
  text-align: center;
}

.text-span {
  font-family: 'Font awesome 5 pro 300', sans-serif;
}

.text-block-72 {
  width: 50px;
  height: 36px;
  margin-right: 15px;
  margin-bottom: 0px;
  padding: 9px;
  border-radius: 8px;
  background-color: #ececec;
  font-family: 'Font awesome 5 pro 400', sans-serif;
  color: #333;
  text-align: center;
}

.div-block-82 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.geral1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
}

.btnforum {
  display: block;
  width: 220px;
  margin-bottom: 40px;
  padding-right: 20px;
  padding-left: 20px;
  border: 1px solid #00985f;
  border-radius: 16px;
  background-color: transparent;
  -webkit-transition: color 500ms ease, background-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease;
  color: #00985f;
  font-weight: 500;
  text-align: center;
}

.btnforum:hover {
  background-color: #00985f;
  color: #fff;
}

.check2 {
  width: 50px;
  height: 36px;
  margin-right: 0px;
  margin-bottom: 0px;
  padding: 9px;
  border-radius: 8px;
  background-color: #ececec;
  font-family: 'Font awesome 5 pro 400', sans-serif;
  color: #333;
  text-align: center;
}

.column-49 {
  padding-left: 50px;
}

.texto-trilha {
  color: #333;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.trilha1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 45px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-weight: 600;
}

.text-span-2 {
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #00985f;
  font-size: 25px;
  font-weight: 300;
  text-decoration: none;
}

.text-block-73 {
  color: #333;
  font-size: 16px;
  font-weight: 700;
}

.text-span-3 {
  color: #00985f;
  font-size: 25px;
}

.div-block-84 {
  width: 445px;
  margin-bottom: 40px;
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 16px;
  background-color: transparent;
  color: #fff;
}

.container-20 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
}

.section-4 {
  margin-top: 0px;
  margin-bottom: 20px;
}

.div-block-85 {
  padding-right: 0px;
}

.text-block-74 {
  display: none;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #333;
  font-size: 25px;
}

.trilha2 {
  margin-top: 50px;
}

.trilha3 {
  margin-top: 50px;
}

.dropdown-list-3 {
  margin-top: -8px;
}

.dropdown-list-3.w--open {
  margin-top: 0px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

.dropdown-list-4 {
  background-color: #fff;
}

.dropdown-list-4.w--open {
  border: 1px solid #ddd;
  border-radius: 8px;
}

.cursopdf-1 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  height: 120px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 30%;
  background-size: auto, cover;
  line-height: 20px;
}

.cursopdf2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  height: 120px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 100%;
  background-size: auto, cover;
  line-height: 20px;
}

.cursopdf-4 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  height: 120px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 10%;
  background-size: auto, cover;
  line-height: 20px;
}

.cursopdf6 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  height: 120px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  line-height: 20px;
}

.cursopdf-7 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  height: 120px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 100%;
  background-size: auto, cover;
  line-height: 20px;
}

.cursopdf-9 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  height: 120px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 10%;
  background-size: auto, cover;
  line-height: 20px;
}

.nomelink {
  color: #333;
  font-weight: 600;
  text-decoration: none;
}

.nomelink:hover {
  text-decoration: underline;
}

.bold-text-3 {
  text-decoration: none;
}

.link-block-3 {
  text-decoration: none;
}

.link-block-4 {
  text-decoration: none;
}

.link-block-5 {
  text-decoration: none;
}

.link-block-6 {
  text-decoration: none;
}

.link-block-7 {
  text-decoration: none;
}

.link-block-8 {
  text-decoration: none;
}

.link-block-9 {
  text-decoration: none;
}

.link-block-10 {
  text-decoration: none;
}

.link-block-11 {
  text-decoration: none;
}

.link-block-12 {
  text-decoration: none;
}

.div-block-87 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.div-block-88 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  margin-left: 0px;
  padding-left: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-content: space-between;
  -ms-flex-line-pack: justify;
  align-content: space-between;
}

.dropdown-2 {
  margin-right: 0px;
  margin-left: 0px;
}

.linha-2 {
  width: 730px;
  height: 1px;
  margin: 0px auto -15px;
  padding-top: 0px;
  border-style: solid;
  border-width: 0px;
  border-color: #fff;
  background-color: #ddd;
  color: #ffa100;
}

.image_2 {
  width: 100px;
  height: 60px;
  border-radius: 16px;
  background-color: #333;
  background-position: 50% 20%;
  background-size: cover;
  color: #333;
}

.image_1 {
  width: 100px;
  height: 60px;
  border-radius: 16px;
  background-color: #333;
  background-position: 50% 50%;
  background-size: cover;
  color: #333;
}

.image_3 {
  width: 100px;
  height: 60px;
  border-radius: 16px;
  background-color: #333;
  background-position: 50% 50%;
  background-size: cover;
  color: #333;
}

.image_4 {
  width: 100px;
  height: 60px;
  border-radius: 16px;
  background-color: #333;
  background-position: 50% 10%;
  background-size: cover;
  color: #333;
}

.image_5 {
  width: 100px;
  height: 60px;
  border-radius: 16px;
  background-color: #333;
  background-position: 50% 30%;
  background-size: cover;
  color: #333;
}

.cursopdf-image3 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  height: 120px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 10%;
  background-size: auto, cover;
  line-height: 20px;
}

.cursopdfimage4 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  height: 120px;
  padding: 17px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-position: 0px 0px, 50% 10%;
  background-size: auto, cover;
  line-height: 20px;
}

.prova2 {
  display: none;
  height: auto;
  margin-top: 50px;
  margin-bottom: 0px;
  padding-top: 35px;
  padding-bottom: 35px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #0e0e0e;
}

.imagep_2 {
  padding-bottom: 155px;
  background-position: 50% 50%;
  background-size: cover;
}

.imagep_3 {
  padding-bottom: 155px;
  background-position: 50% 50%;
  background-size: cover;
}

.imagep_4 {
  padding-bottom: 155px;
  background-position: 50% 50%;
  background-size: cover;
}

.banner2-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 300px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  background-position: 0px 0px, 50% 10%;
  background-size: auto, cover;
}

.dropdown-list-5 {
  background-color: #fff;
}

.dropdown-list-5.w--open {
  border: 1px solid #ddd;
  border-radius: 8px;
}

.icon-5 {
  margin-top: 26px;
  margin-right: 25px;
  margin-bottom: 21px;
  color: #333;
  font-size: 11px;
  font-weight: 100;
}

.cursos_menu:hover {
  color: #ffa100;
}

.banner2trilhas {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 300px;
  margin-top: 80px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  background-position: 0px 0px, 50% 10%;
  background-size: auto, cover;
}

.dropdown-toggle-3 {
  display: none;
}

.div-block-98 {
  display: none;
  padding: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 1px 1px 8px 0 #d6d6d6;
}

.div-block-99 {
  width: 600px;
  height: 6px;
  margin-right: 0px;
  margin-bottom: 0px;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  border-radius: 100px;
  background-color: #dbdbdb;
}

.text-block-75 {
  margin-bottom: 15px;
  color: #333;
  font-size: 17px;
  font-weight: 500;
}

.text-block-76 {
  margin-right: 20px;
  color: #333;
  font-size: 20px;
  font-weight: 700;
}

.div-block-100 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.grafico2 {
  width: 200px;
  height: 6px;
  margin-right: 0px;
  margin-bottom: 0px;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  border-radius: 100px;
  background-color: #00985f;
  background-image: -webkit-gradient(linear, left top, right top, from(#00985f), to(#07c51a));
  background-image: linear-gradient(90deg, #00985f, #07c51a);
}

.div-block-301 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.andamento {
  margin-top: 60px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: hsla(0, 0%, 97.3%, 0.78);
}

.premiosloja {
  margin-top: 60px;
}

.columns-12 {
  margin-top: 20px;
}

.btnconcluir {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
  margin-bottom: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

._21 {
  width: 100px;
  height: 6px;
  margin-right: 0px;
  margin-bottom: 0px;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  border-radius: 100px;
  background-color: #00985f;
  background-image: -webkit-gradient(linear, left top, right top, from(#00985f), to(#07c51a));
  background-image: linear-gradient(90deg, #00985f, #07c51a);
}

._01-copy {
  width: 10px;
  height: 6px;
  margin-right: 0px;
  margin-bottom: 0px;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  border-radius: 100px;
  background-color: #00985f;
  background-image: -webkit-gradient(linear, left top, right top, from(#00985f), to(#07c51a));
  background-image: linear-gradient(90deg, #00985f, #07c51a);
}

.slide-3 {
  height: 100vh;
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.slider-3 {
  height: 100vh;
  padding-top: 0px;
}

.mask {
  height: 100vh;
}

.container-24 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin-top: 0px;
  padding-top: 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.text-block-77 {
  width: 600px;
  margin-top: 20px;
  margin-bottom: 30px;
  color: #fff;
}

.heading-6 {
  width: 600px;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 60px;
}

.icon-8 {
  color: hsla(0, 0%, 100%, 0.5);
  font-size: 30px;
}

.icon-9 {
  color: hsla(0, 0%, 100%, 0.5);
  font-size: 30px;
}

.slide-nav-2 {
  color: hsla(0, 0%, 100%, 0.5);
  font-size: 10px;
}

.slide-4 {
  top: 0px;
  padding-top: 0px;
  background-position: 0px 0px, 50% 50px;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.online:hover {
  color: #ffa100;
}

.online.w--current {
  color: #ffa100;
}

.presenciais:hover {
  color: #ffa100;
}

.presenciais.w--current {
  color: #ffa100;
}

.certificadossubmenu:hover {
  color: #ffa100;
}

.certificadossubmenu.w--current {
  color: #ffa100;
}

.div-block-303 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 50px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.div-block-304 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.div-block-305 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 60px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-305.segunda {
  margin-bottom: 60px;
}

.left-arrow {
  margin-top: 80px;
}

.right-arrow {
  margin-top: 80px;
}

.section-5 {
  margin-top: 130px;
}

.section-6 {
  margin-top: 130px;
}

.section-7 {
  margin-top: 130px;
}

.div-block-306 {
  width: 150px;
  height: 80px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.text-block-78 {
  margin-bottom: 8px;
  color: #fff;
  font-size: 13px;
}

.div-block-307 {
  width: 1px;
  height: 50px;
  margin-right: 40px;
  margin-left: 40px;
  border: 0px solid transparent;
  background-color: #f8f8f8;
}

.text-block-79 {
  color: #fff;
  font-size: 13px;
}

.text-block-80 {
  font-size: 20px;
  font-weight: 700;
}

.div-block-308 {
  margin-top: 40px;
  margin-bottom: 0px;
}

.div-block-309 {
  background-color: transparent;
}

.discurss-o1-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  padding: 10px 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 16px;
  background-color: transparent;
}

.div-block-310 {
  margin-top: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 16px;
  background-color: rgba(51, 51, 51, 0.04);
}

.discurss-o-2-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  padding: 10px 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 16px;
  background-color: transparent;
}

.linhaforum {
  width: 600px;
  height: 1px;
  margin: 10px auto;
  border-style: solid;
  border-width: 1px;
  border-color: #e6e6e6;
}

.categoria {
  margin-bottom: 0px;
  color: #616161;
  font-size: 11px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
}

.columns-13 {
  margin-bottom: 30px;
}

.search-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-left: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 20px;
}

.search-input-2 {
  display: none;
  width: 180px;
  margin-bottom: 0px;
  padding: 5px 10px;
  border: 1px solid transparent;
  border-radius: 20px;
  background-color: transparent;
  font-size: 12px;
}

.search-button-2 {
  border: 1px solid transparent;
  background-color: transparent;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #333;
}

.search-button-2:hover {
  color: #ffa100;
}

.dropdown-toggle-4 {
  z-index: 10;
}

.div-block-311 {
  margin-right: 40px;
  margin-left: 0px;
  padding-right: 0px;
}

.section-8 {
  margin-top: 0px;
  padding-top: 130px;
}

.em-andamento {
  margin-top: 10px;
  border-radius: 16px;
}

.icon-10.accordion-icon {
  color: rgba(0, 0, 0, 0.6);
  font-size: 32px;
  line-height: 30px;
  cursor: pointer;
}

.icon-10.accordion-icon {
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  line-height: 30px;
  cursor: pointer;
}

.accordion-item-trigger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.accordion-item-content {
  overflow: hidden;
  margin-top: 10px;
  margin-right: 30px;
}

.div-block-18-trajetoria {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nao_iniciado {
  overflow: auto;
  margin-bottom: 0px;
  padding: 24px 30px 14px;
  border-style: solid;
  border-width: 1px;
  border-color: #eee;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: none;
}

.listagem-curso {
  position: static;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: auto;
  width: auto;
  padding: 31px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 1px 8px 0 #d6d6d6;
}

.andamento_foto_1 {
  width: 100px;
  height: 60px;
  border-radius: 16px;
  background-color: #333;
  background-position: 50% 50%;
  background-size: cover;
  color: #333;
}

.nomelink-2 {
  margin-left: 0px;
  color: #333;
  font-weight: 600;
  text-decoration: none;
}

.nomelink-2:hover {
  text-decoration: underline;
}

.andamento_foto_2 {
  width: 100px;
  height: 60px;
  border-radius: 16px;
  background-color: #333;
  background-position: 50% 50%;
  background-size: cover;
  color: #333;
}

.andamento_foto_3 {
  width: 100px;
  height: 60px;
  border-radius: 16px;
  background-color: #333;
  background-position: 50% 50%;
  background-size: cover;
  color: #333;
}

.andamento_foto_4 {
  width: 100px;
  height: 60px;
  border-radius: 16px;
  background-color: #333;
  background-position: 50% 0%;
  background-size: cover;
  color: #333;
}

.div-block-313 {
  margin-right: 50px;
}

.grafico1 {
  width: 120px;
  height: 6px;
  margin-right: 0px;
  margin-bottom: 0px;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  border-radius: 100px;
  background-color: #00985f;
  background-image: -webkit-gradient(linear, left top, right top, from(#00985f), to(#07c51a));
  background-image: linear-gradient(90deg, #00985f, #07c51a);
}

.grafico3 {
  width: 10px;
  height: 6px;
  margin-right: 0px;
  margin-bottom: 0px;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  border-radius: 100px;
  background-color: #00985f;
  background-image: -webkit-gradient(linear, left top, right top, from(#00985f), to(#07c51a));
  background-image: linear-gradient(90deg, #00985f, #07c51a);
}

.link-5 {
  color: red;
}

.link-6 {
  color: red;
}

.link-7 {
  color: red;
}

.link-8 {
  color: red;
}

.link-9 {
  color: red;
}

.link-10 {
  color: red;
}

.columns-14 {
  margin-top: 0px;
}

.div-block-314 {
  padding-top: 25px;
}

.text-block-85 {
  margin-bottom: 60px;
}

.mais {
  display: none;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: #00985f;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  text-align: center;
}

.mais:hover {
  -webkit-transform: scale(1.02) translate(0px, -5px);
  -ms-transform: scale(1.02) translate(0px, -5px);
  transform: scale(1.02) translate(0px, -5px);
}

.form-block-3 {
  margin-bottom: 0px;
}

.link-11 {
  margin-left: 10px;
  color: #333;
  font-size: 12px;
}

.checkbox-simple {
  width: 24px;
  height: 24px;
  margin-top: 0px;
  margin-right: 1em;
  margin-left: 0px;
  border-color: rgba(49, 47, 100, 0.1);
}

.icon-arrow {
  display: none;
  height: 20px;
  max-width: 20%;
  margin-top: 9px;
  color: #333;
}

.dropdown-wrapper {
  margin-top: 0px;
  padding: 0px 40px;
  background-color: #fff;
}

.section-1 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 903px;
  margin-left: 14vw;
  padding: 4vh;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-align: center;
}

.section-1.full {
  margin-left: 0vw;
  padding-top: 10vh;
  padding-bottom: 10vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-1.full.custompaddings {
  margin-top: 0px;
  padding: 20px 6vw;
}

.icon-compplete {
  display: none;
  max-width: 30%;
  margin-left: 1em;
}

.white-box {
  position: relative;
  overflow: hidden;
  padding: 3vh;
  border-radius: 8px;
  background-color: #fff;
  text-align: center;
}

.white-box.wrapper-form {
  width: 840px;
  max-width: none;
  min-width: auto;
  margin-right: auto;
  margin-left: auto;
  padding: 40px;
  box-shadow: 0 3px 6px 3px rgba(49, 47, 100, 0.1);
}

.white-box.wrapper-form.m-0 {
  padding: 0px;
  box-shadow: 0 2px 6px 3px rgba(49, 47, 100, 0.1);
}

.white-box.wrapper-form.m-0.in-dropdown {
  z-index: 99;
  margin-top: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background-color: #fff;
  box-shadow: 0 8px 10px 3px rgba(49, 47, 100, 0.08);
}

.service-title {
  color: #0f4dbc;
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
}

.checkbox-label {
  margin-bottom: 0px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.input-label {
  margin-bottom: 2.5em;
  color: #333;
  font-size: 16px;
  line-height: 1.6em;
  font-weight: 700;
  text-align: left;
}

.wrapper-simple {
  position: relative;
  z-index: 2;
  max-width: 1200px;
}

.wrapper-simple.margins-40 {
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 24px 40px;
  border-radius: 8px;
}

.wrapper-simple.margins-40.dropdown-toggle {
  display: block;
  margin-bottom: -4px;
  padding-top: 0.3em;
  padding-bottom: 0.5em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 13px -8px #646262;
  -webkit-transition: box-shadow 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out;
  cursor: pointer;
}

.wrapper-simple.margins-40.dropdown-toggle:hover {
  box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
}

.wrapper-simple.max-width {
  width: auto;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
}

.wrapper-simple.max-width.custom-size {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.wrapper-flex-horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.wrapper-flex-horizontal.space {
  margin-top: 1em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  color: #668cad;
  font-size: 1em;
  line-height: 1.6em;
}

.wrapper-flex-horizontal.space.no-margin-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.wrapper-flex-horizontal.align-left {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.wrapper-flex-horizontal.align-just {
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 0.25fr 3fr 0.25fr;
  grid-template-columns: 0.25fr 3fr 0.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.from-in-section {
  margin-top: 2em;
  margin-bottom: 3em;
}

.checkbox-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 31.333333333333336%;
  padding: 1em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(49, 47, 100, 0.1);
  border-radius: 4px;
  -webkit-transition: box-shadow 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out;
}

.checkbox-field:hover {
  border-top-color: rgba(49, 47, 100, 0.06);
  box-shadow: 0 6px 16px 0 rgba(49, 47, 100, 0.3);
}

.checkbox-field.horizontal {
  width: 100%;
  margin-bottom: 16px;
  padding-top: 1px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-width: 0.09em;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  border-radius: 8px;
  background-color: #fff;
  -webkit-transition: box-shadow 300ms ease-in-out, -webkit-transform 300ms ease;
  transition: box-shadow 300ms ease-in-out, -webkit-transform 300ms ease;
  transition: transform 300ms ease, box-shadow 300ms ease-in-out;
  transition: transform 300ms ease, box-shadow 300ms ease-in-out, -webkit-transform 300ms ease;
}

.checkbox-field.horizontal:hover {
  border-color: transparent;
  box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
  -webkit-transform: scale(1.02) translate(0px, -1px);
  -ms-transform: scale(1.02) translate(0px, -1px);
  transform: scale(1.02) translate(0px, -1px);
}

.body-12 {
  background-color: #fff;
  font-family: Montserrat, sans-serif;
}

.heading-8 {
  margin-top: 0px;
  padding-left: 0px;
  color: #333;
  font-size: 40px;
  line-height: 44px;
  font-weight: 700;
}

.div-block-317 {
  width: 100px;
  height: 1px;
  margin-left: -42px;
  padding-right: 0px;
  padding-left: 62px;
  background-color: #ffa100;
  color: #fadd12;
}

.titulo-prova {
  display: block;
  margin-top: 20px;
}

.setas {
  height: 20px;
  margin-top: 29px;
  margin-right: 25px;
  margin-left: 10px;
  font-family: 'Font awesome 5 pro solid 900', sans-serif;
  color: #0f4dbc;
  font-size: 16px;
}

.check {
  display: none;
  margin-top: 0px;
  font-family: 'Font awesome 5 pro solid 900', sans-serif;
  color: #00985f;
  font-size: 30px;
}

.div-block-318 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.progress-bar {
  width: 70%;
  height: 1em;
  min-width: 280px;
  border-radius: 8px;
  background-image: -webkit-gradient(linear, left top, right top, from(#3c398d), to(#3c398d));
  background-image: linear-gradient(90deg, #3c398d, #3c398d);
}

.progress-bar.color {
  width: 25%;
  min-width: auto;
  background-image: -webkit-gradient(linear, left top, right top, from(#3c398d), to(#287cdc));
  background-image: linear-gradient(90deg, #3c398d, #287cdc);
}

.progress-bar.color.progress-zero {
  width: 0px;
  height: 10px;
  border-radius: 20px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#07c51a), to(#00985f));
  background-image: linear-gradient(180deg, #07c51a, #00985f);
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.progress-bar.color.progress-zero._2 {
  display: block;
  width: 90%;
  margin-left: -151px;
}

.progress-bar.white {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid solid solid none;
  border-width: 1px;
  border-color: rgba(49, 47, 100, 0.1) rgba(49, 47, 100, 0.3) rgba(49, 47, 100, 0.3);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f0f0f7), to(#f0f0f7));
  background-image: linear-gradient(180deg, #f0f0f7, #f0f0f7);
}

.progress-bar.white.long-bar {
  width: 600px;
  height: 18px;
  max-width: 720px;
  margin-right: 40px;
  border: 1px solid transparent;
  border-radius: 20px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#dbdbdb), to(#dbdbdb));
  background-image: linear-gradient(180deg, #dbdbdb, #dbdbdb);
}

.logo {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.logo.no-margins {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
}

.service-title-2 {
  color: #267bdb;
  font-size: 1.4em;
  font-weight: 500;
}

.service-title-2.medium {
  color: #277bdb;
  font-size: 1.4em;
  font-weight: 400;
}

.service-title-2.medium.service-bar {
  display: block;
  margin-top: 10px;
  margin-right: 36px;
  margin-left: 0px;
  color: #333;
  font-size: 18px;
  font-weight: 700;
}

.btn-7 {
  margin-top: 30px;
  padding: 20px 50px 14px;
  background-image: linear-gradient(45deg, #fd3fb3, #fd3f81 31%, #fd3e4f 78%, #fd3e4f);
  -webkit-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
  font-size: 16px;
  font-weight: 800;
  text-align: center;
}

.btn-7:hover {
  background-color: #fd3fb3;
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.15), hsla(0, 0%, 100%, 0.15)), linear-gradient(45deg, #fd3fb3, #fd3f81 31%, #fd3e4f 78%, #fd3e4f);
}

.btn-7.nav {
  height: 42px;
  margin-top: -3px;
  margin-bottom: -5px;
  margin-left: 10px;
  padding: 10px 20px 12px;
  border-radius: 8px;
  background-color: #277bdb;
  background-image: none;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-transform: none;
}

.btn-7.nav:hover {
  background-color: #185fb1;
}

.btn-7.nav.w--current {
  position: relative;
  z-index: -1;
  opacity: 0.5;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.btn-7.nav.inactive-request {
  display: none;
  background-color: #dbdbe5;
  color: #a5a5b8;
}

.text-subtitle {
  font-size: 3.4vh;
  line-height: 1.6em;
  font-weight: 300;
}

.text-subtitle.dark {
  font-size: 1.6em;
  font-weight: 300;
}

.text-subtitle.dark.tiny {
  display: inline-block;
  margin-top: 0.4em;
  font-size: 1.2em;
  text-align: left;
}

.text-subtitle.dark.tiny.align-center {
  display: none;
  margin-top: 0em;
  margin-right: auto;
  margin-left: auto;
  padding-right: 12px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.fixed-submit-bar-2 {
  position: fixed;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  padding: 0.8em 6vw;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 20px 2px rgba(60, 60, 60, 0.15);
}

.lottie-animation {
  display: none;
  width: 80px;
}

.btn3-2 {
  display: block;
  padding-right: 38px;
  padding-left: 38px;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  border: 1px solid transparent;
  border-radius: 16px;
  background-color: #00985f;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  color: #fff;
  font-weight: 500;
  text-align: center;
}

.btn3-2:hover {
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
}

.section-11 {
  display: block;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.lottie-animation-2-copy {
  width: auto;
  background-color: transparent;
}

.lottie-animation-2-copy {
  width: 200px;
  height: auto;
  margin-bottom: 0px;
  background-color: transparent;
}

.heading-9 {
  width: auto;
  margin-top: 0px;
  color: #00985f;
  font-size: 50px;
  text-transform: uppercase;
}

.container-26 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-86 {
  font-size: 18px;
  text-align: center;
}

.body-13 {
  background-color: #fff;
}

.check-questao-2 {
  display: none;
  margin-top: 0px;
  font-family: 'Font awesome 5 pro solid 900', sans-serif;
  color: #00985f;
  font-size: 30px;
}

.check-questap-3 {
  display: none;
  margin-top: 0px;
  font-family: 'Font awesome 5 pro solid 900', sans-serif;
  color: red;
  font-size: 30px;
}

.check-questao-4 {
  display: none;
  margin-top: 0px;
  font-family: 'Font awesome 5 pro solid 900', sans-serif;
  color: #00985f;
  font-size: 30px;
}

.progress-bar-copy {
  width: 70%;
  height: 1em;
  min-width: 280px;
  border-radius: 8px;
  background-image: -webkit-gradient(linear, left top, right top, from(#3c398d), to(#3c398d));
  background-image: linear-gradient(90deg, #3c398d, #3c398d);
}

.progress-bar-copy.color {
  width: 25%;
  min-width: auto;
  background-image: -webkit-gradient(linear, left top, right top, from(#3c398d), to(#287cdc));
  background-image: linear-gradient(90deg, #3c398d, #287cdc);
}

.progress-bar-copy.color.progress-zero {
  width: 25%;
  height: 18px;
  border-radius: 20px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#07c51a), to(#00985f));
  background-image: linear-gradient(180deg, #07c51a, #00985f);
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.progress-bar-copy.color.progress-zero._2 {
  display: block;
  width: 90%;
  margin-left: -151px;
}

.progress-bar-copy.white {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid solid solid none;
  border-width: 1px;
  border-color: rgba(49, 47, 100, 0.1) rgba(49, 47, 100, 0.3) rgba(49, 47, 100, 0.3);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f0f0f7), to(#f0f0f7));
  background-image: linear-gradient(180deg, #f0f0f7, #f0f0f7);
}

.progress-bar-copy.white.long-bar {
  width: 600px;
  height: 18px;
  max-width: 720px;
  margin-right: 40px;
  border: 1px solid transparent;
  border-radius: 20px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#dbdbdb), to(#dbdbdb));
  background-image: linear-gradient(180deg, #dbdbdb, #dbdbdb);
}

.menssagem-sucesso {
  border-radius: 12px;
  background-color: #359973;
  color: #fff;
}


.campoemail {
  height: 60px;
  margin-bottom: 0px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  text-align: center;
}

.btn-email {
  width: 30%;
  height: 60px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 0px 12px 12px 0px;
  background-color: #359973;
  text-transform: uppercase;
}

.btn-email:hover {
  background-color: #30009c;
  color: #fff;
}

.form-news {
  margin-bottom: 0px;
}

.link-fechar {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
}

.modal {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2000;
  display: none;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
}

.bloco-modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 800px;
  height: 500px;
  margin-top: 127px;
  margin-right: auto;
  margin-left: auto;
  padding: 0px 30px 120px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #fff;
}

.email {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-10 {
  color: #333;
}

.icone-fechar {
  width: 32px;
}

.contai {
  display: block;
  max-width: 940px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lottie-animation-2 {
  width: 250px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.heading-11 {
  position: relative;
  margin-top: -192px;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.text-block-87 {
  position: relative;
}

.section-12 {
  height: 100px;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: #f8f8f8;
}

.section-13 {
  display: none;
  margin-top: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #00985f;
}

.paragraph-6 {
  color: #616161;
}

.link-12 {
  margin-left: 5px;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #fff;
  font-size: 12px;
  text-decoration: none;
}

.radio-button-field {
  margin-bottom: 30px;
  padding: 12px 34px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  border-radius: 8px;
  text-align: left;
}

.radio-button-field-2 {
  margin-bottom: 10px;
  padding: 12px 34px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  border-radius: 8px;
  text-align: left;
}

.radio-button-field-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding: 12px 34px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  border-radius: 8px;
  text-align: left;
}

.radio-button-field-4 {
  text-align: left;
}

.radio-button-field-5 {
  margin-bottom: 10px;
  padding: 12px 34px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  border-radius: 8px;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.progress-bar-maior {
  width: 70%;
  height: 1em;
  min-width: 280px;
  border-radius: 8px;
  background-image: -webkit-gradient(linear, left top, right top, from(#3c398d), to(#3c398d));
  background-image: linear-gradient(90deg, #3c398d, #3c398d);
}

.progress-bar-maior.color {
  width: 25%;
  min-width: auto;
  background-image: -webkit-gradient(linear, left top, right top, from(#3c398d), to(#287cdc));
  background-image: linear-gradient(90deg, #3c398d, #287cdc);
}

.progress-bar-maior.color.progress-zero {
  width: 20%;
  height: 18px;
  border-radius: 20px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#07c51a), to(#00985f));
  background-image: linear-gradient(180deg, #07c51a, #00985f);
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.progress-bar-maior.color.progress-zero._2 {
  display: block;
  width: 90%;
  margin-left: -151px;
}

.progress-bar-maior.white {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid solid solid none;
  border-width: 1px;
  border-color: rgba(49, 47, 100, 0.1) rgba(49, 47, 100, 0.3) rgba(49, 47, 100, 0.3);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f0f0f7), to(#f0f0f7));
  background-image: linear-gradient(180deg, #f0f0f7, #f0f0f7);
}

.progress-bar-maior.white.long-bar {
  width: 600px;
  height: 10px;
  max-width: 720px;
  margin-right: 40px;
  border: 1px solid transparent;
  border-radius: 20px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#dbdbdb), to(#dbdbdb));
  background-image: linear-gradient(180deg, #dbdbdb, #dbdbdb);
}

.radio-button {
  width: 15px;
  height: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-width: 2px;
  border-color: #e0d8d8;
  border-radius: 100%;
}

.radio-button-label {
  margin-bottom: 0px;
  padding-left: 20px;
}

.radio-button-label-2 {
  padding-left: 20px;
}

.radio-button-label-3 {
  margin-bottom: 0px;
  padding-left: 20px;
}

.radio-button-label-4 {
  padding-left: 20px;
}

.btnhome-copy {
  display: block;
  margin-bottom: 0px;
  padding-right: 38px;
  padding-left: 38px;
  border: 1px solid #00985f;
  border-radius: 16px;
  background-color: transparent;
  -webkit-transition: color 500ms ease, background-color 500ms ease, -webkit-transform 500ms ease;
  transition: color 500ms ease, background-color 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, color 500ms ease, background-color 500ms ease;
  transition: transform 500ms ease, color 500ms ease, background-color 500ms ease, -webkit-transform 500ms ease;
  color: #00985f;
  font-weight: 500;
  text-align: center;
}

.btnhome-copy:hover {
  background-color: #00985f;
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
  color: #fff;
}

.div-block-319 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.div-block-320 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.div-block-321 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.div-block-322 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.resposta1 {
  margin-bottom: 10px;
  padding: 12px 34px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  border-radius: 8px;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.resposta2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding: 12px 34px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  border-radius: 8px;
  text-align: left;
}

.resposta3 {
  margin-bottom: 10px;
  padding: 12px 34px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  border-radius: 8px;
  text-align: left;
}

.resposta4 {
  margin-bottom: 30px;
  padding: 12px 34px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  border-radius: 8px;
  text-align: left;
}

.resposta1-copy {
  margin-bottom: 10px;
  padding: 12px 34px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  border-radius: 8px;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.resposta-1-1 {
  margin-bottom: 10px;
  padding: 12px 34px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  border-radius: 8px;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.resposta-3-1 {
  margin-bottom: 10px;
  padding: 12px 34px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  border-radius: 8px;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.resposta3-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding: 12px 34px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  border-radius: 8px;
  text-align: left;
}

.resposta3-3 {
  margin-bottom: 10px;
  padding: 12px 34px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  border-radius: 8px;
  text-align: left;
}

.resposta3-4 {
  margin-bottom: 30px;
  padding: 12px 34px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  border-radius: 8px;
  text-align: left;
}

.resposta4-1 {
  margin-bottom: 10px;
  padding: 12px 34px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  border-radius: 8px;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.resposta4-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding: 12px 34px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  border-radius: 8px;
  text-align: left;
}

.resposta4-3 {
  margin-bottom: 10px;
  padding: 12px 34px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  border-radius: 8px;
  text-align: left;
}

.resposta4-4 {
  margin-bottom: 30px;
  padding: 12px 34px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.82);
  border-radius: 8px;
  text-align: left;
}

.radio-button-2 {
  width: 17px;
  height: 15px;
  margin-left: -20px;
  border-width: 2px;
  border-color: #e0d8d8;
  border-radius: 50px;
}

.radio-button-3 {
  width: 15px;
  height: 15px;
  border: 2px solid #e0d8d8;
  border-radius: 50px;
}

.radio-button-4 {
  width: 15px;
  height: 15px;
  border: 2px solid #e0d8d8;
  border-radius: 50px;
}

.radio-button-5 {
  width: 17px;
  height: 15px;
  border: 2px solid #e0d8d8;
  border-radius: 50px;
}

.radio-button-6 {
  width: 15px;
  height: 15px;
  border-width: 2px;
  border-color: #e0d8d8;
}

.radio-button-7 {
  width: 15px;
  height: 15px;
  border: 2px solid #e0d8d8;
  border-radius: 50px;
}

.radio-button-8 {
  width: 17px;
  height: 15px;
  border: 2px solid #e0d8d8;
}

.radio-button-9 {
  width: 15px;
  height: 15px;
  border-width: 2px;
  border-color: #e0d8d8;
}

.radio-button-10 {
  width: 15px;
  height: 15px;
  border: 2px solid #e0d8d8;
}

.radio-button-11 {
  width: 17px;
  height: 15px;
  border-width: 2px;
  border-color: #e0d8d8;
}

.radio-button-12 {
  width: 15px;
  height: 15px;
  border: 2px solid #e0d8d8;
}

.radio-button-13 {
  width: 15px;
  height: 15px;
  border-width: 2px;
  border-color: #e0d8d8;
}

html.w-mod-js *[data-ix="titulo"] {
  opacity: 0;
  -webkit-transform: translate(0px, 50px);
  -ms-transform: translate(0px, 50px);
  transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="cursoshome"] {
  opacity: 0;
  -webkit-transform: translate(0px, 30px);
  -ms-transform: translate(0px, 30px);
  transform: translate(0px, 30px);
}

html.w-mod-js *[data-ix="abrir-seach"] {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

html.w-mod-js *[data-ix="titulo-2"] {
  opacity: 0;
  -webkit-transform: translate(0px, 50px);
  -ms-transform: translate(0px, 50px);
  transform: translate(0px, 50px);
}

@media screen and (max-width: 991px) {
  .nav-menu {
    margin-left: 0px;
    background-color: #0e0e0e;
  }
  .container {
    display: block;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .div-block-2 {
    height: 330px;
  }
  .video {
    width: 229px;
    background-position: 0px 0px, 50% 10%;
    background-size: auto, cover;
  }
  .video-copy {
    width: 168px;
    height: 108px;
  }
  .columns-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .heading-3 {
    padding-right: 0px;
  }
  .div-block-14 {
    padding-right: 0px;
  }
  .cursos {
    margin-right: 0px;
    margin-left: 0px;
    color: #fff;
  }
  .camila2 {
    padding: 20px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    color: #fff;
  }
  .camila-icon {
    margin-right: 0px;
    padding-left: 0px;
    color: #fff;
  }
  .videointerna {
    display: block;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .div-block-17 {
    height: 350px;
  }
  .curtiir2 {
    text-align: center;
  }
  .menu-button {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    color: #0e0e0e;
  }
  .menu-button.w--open {
    border-radius: 50px;
    background-color: #0e0e0e;
  }
  .outros-videos {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .linha {
    width: 580px;
    margin-right: auto;
    margin-left: auto;
  }
  .text-field {
    width: 351px;
    margin-right: 0px;
  }
  .text-field-2 {
    width: 351px;
    padding-right: 12px;
  }
  .icontest {
    color: #f9461c;
  }
  .div-block-25 {
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .div-block-26 {
    background-color: rgba(122, 121, 121, 0.51);
  }
  .caixamsg {
    width: 726px;
    height: 80px;
    padding-right: 12px;
  }
  .div-block-28 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .div-block-29 {
    margin-right: 25px;
  }
  .trilhasmenu {
    margin-right: 0px;
    margin-left: 0px;
    color: #fff;
  }
  .menulive {
    margin-right: 0px;
    margin-left: 0px;
    color: #fff;
  }
  .forum {
    margin-right: 0px;
    margin-left: 0px;
    color: #fff;
  }
  .bold-text {
    margin-right: 0px;
    padding-right: 0px;
  }
  .icon-3 {
    color: #ffa100;
  }
  .columns-6 {
    display: block;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .column-21 {
    display: block;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .curso {
    width: 168px;
    height: 108px;
  }
  .success-message {
    max-width: 738px;
  }
  .error-message {
    max-width: 738px;
  }
  .container-10 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .div-block-37 {
    width: 401px;
  }
  .cursopdf-2 {
    width: 168px;
    height: 108px;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .iconplus-copy {
    color: #f9461c;
  }
  .div-block-38 {
    width: auto;
    height: auto;
  }
  .jan {
    margin-right: 10px;
  }
  .fev {
    margin-right: 10px;
  }
  .mar {
    margin-right: 10px;
  }
  .abr {
    margin-right: 10px;
  }
  .maio {
    margin-right: 10px;
  }
  .jun {
    margin-right: 10px;
  }
  .dez {
    margin-right: 0px;
  }
  .julho {
    margin-right: 10px;
  }
  .ago {
    margin-right: 10px;
  }
  .set {
    margin-right: 10px;
  }
  .out {
    margin-right: 10px;
  }
  .nov {
    margin-right: 10px;
  }
  .atividades {
    margin-top: 134px;
  }
  .linhacert {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .dropdown-toggle {
    padding: 0px;
  }
  .dropdown-list.w--open {
    border-color: transparent;
    background-color: #fff;
  }
  .btnpremios {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .videolive {
    height: 350px;
  }
  .text-block-50 {
    margin-right: -146px;
  }
  .div-block-63 {
    width: auto;
  }
  .dropdown-link {
    color: #333;
  }
  .dropdown-link-2 {
    border: 1px solid transparent;
    color: #333;
  }
  .blocopdf {
    height: 350px;
  }
  .classifica-o2 {
    margin-left: 0px;
  }
  .div-block-68 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .div-block-69 {
    margin-left: -53px;
    padding-left: 0px;
  }
  .column-41 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .video2 {
    width: 229px;
  }
  .video-3 {
    width: 229px;
  }
  .video4 {
    width: 168px;
    height: 108px;
  }
  .video5 {
    width: 168px;
    height: 108px;
  }
  .video-6 {
    width: 168px;
    height: 108px;
  }
  .video7 {
    width: 229px;
  }
  .video8 {
    width: 229px;
  }
  .video9 {
    width: 229px;
  }
  .video10 {
    width: 229px;
  }
  .video11 {
    width: 229px;
  }
  .video23 {
    width: 229px;
  }
  .text-block-63 {
    color: #fff;
  }
  .dropdown {
    margin-right: 0px;
    margin-left: 0px;
  }
  .nomebold {
    margin-right: -146px;
  }
  .texto-banner-2 {
    padding-right: 0px;
  }
  .textobannerhome {
    margin-right: 0px;
    padding-right: 0px;
  }
  .columns-11 {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
  }
  .textobanner2 {
    padding-right: 0px;
  }
  .cursopdf-1 {
    width: 168px;
    height: 108px;
  }
  .cursopdf2 {
    width: 168px;
    height: 108px;
  }
  .cursopdf-4 {
    width: 168px;
    height: 108px;
  }
  .cursopdf6 {
    width: 168px;
    height: 108px;
  }
  .cursopdf-7 {
    width: 168px;
    height: 108px;
  }
  .cursopdf-9 {
    width: 168px;
    height: 108px;
  }
  .cursopdf-image3 {
    width: 168px;
    height: 108px;
  }
  .cursopdfimage4 {
    width: 168px;
    height: 108px;
  }
  .icon-5 {
    color: #fff;
  }
  .cursos_menu {
    color: #fff;
  }
  .div-block-99 {
    width: 450px;
  }
  .slide-3 {
    background-position: 0px 0px, 50% 50%;
  }
  .container-24 {
    padding-left: 52px;
  }
  .text-block-77 {
    margin-bottom: 22px;
  }
  .slide-4 {
    background-position: 50% 50%;
  }
  .listagem-curso {
    padding-right: 20px;
    padding-left: 20px;
  }
  .mais {
    background-color: rgba(122, 121, 121, 0.51);
  }
  .section-1 {
    min-width: auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .white-box.wrapper-form.m-0.in-dropdown {
    width: 100%;
  }
  .wrapper-simple.margins-40.dropdown-toggle {
    width: 100%;
  }
  .wrapper-simple.max-width {
    width: 100%;
  }
  .wrapper-simple.max-width.custom-size {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .progress-bar.color.progress-zero {
    width: 34%;
  }
  .progress-bar.white.long-bar {
    width: 190px;
    max-width: 110px;
    min-width: 210px;
  }
  .service-title-2.medium.service-bar {
    font-size: 1em;
    line-height: 20px;
  }
  .btn-7 {
    padding: 15px 40px;
    font-size: 14px;
  }
  .btn-7.nav {
    display: block;
    width: 40%;
    margin-top: 15px;
    padding-top: 12px;
    padding-bottom: 10px;
    font-size: 14px;
  }
  .fixed-submit-bar-2 {
    padding-right: 2vw;
    padding-left: 2vw;
  }
  .progress-bar-copy.color.progress-zero {
    width: 34%;
  }
  .progress-bar-copy.white.long-bar {
    width: 190px;
    max-width: 110px;
    min-width: 210px;
  }
  .form-news {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .bloco-modal {
    width: 80%;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .contai {
    display: block;
    margin-top: 120px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .column-54 {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .progress-bar-maior.color.progress-zero {
    width: 34%;
  }
  .progress-bar-maior.white.long-bar {
    width: 190px;
    max-width: 110px;
    min-width: 210px;
  }
}

@media screen and (max-width: 767px) {
  .nav-menu {
    border-radius: 8px;
  }
  .container {
    display: block;
  }
  .video {
    width: auto;
    height: 448px;
  }
  .text-block-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .texto-interna {
    width: auto;
  }
  .div-interna {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 544px;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid transparent;
  }
  .titulo {
    margin-right: 10px;
    margin-left: 10px;
  }
  .video-copy {
    width: auto;
    height: 347px;
  }
  .heading-3 {
    margin-left: 10px;
    padding-right: 184px;
  }
  .div-block-14 {
    margin-right: 10px;
    margin-left: 10px;
    padding-right: 0px;
  }
  .cursos {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .container-5 {
    padding-right: 10px;
  }
  .div-block-17 {
    height: 330px;
  }
  .blocapoio1 {
    margin-right: 0px;
    margin-bottom: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .menu-button {
    margin-right: 10px;
  }
  .outros-videos {
    display: block;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .linha {
    position: relative;
    left: 27px;
    right: 0px;
    z-index: 0;
    display: block;
    width: 2px;
    height: 1600px;
    margin: 0px auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-color: #d1d1d1;
    background-color: #e4e0e0;
    -o-object-fit: fill;
    object-fit: fill;
  }
  .div-block-23 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .column {
    padding-top: 173px;
    padding-bottom: 265px;
  }
  .column-2 {
    padding-top: 173px;
    padding-bottom: 293px;
  }
  .column-3 {
    padding-top: 173px;
    padding-bottom: 294px;
  }
  .column-4 {
    padding-top: 173px;
    padding-bottom: 291px;
  }
  .trilhas {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .videos3 {
    margin-left: 0px;
    padding-left: 0px;
  }
  .formulario {
    width: 100%;
  }
  .text-field {
    width: 255px;
  }
  .text-field-2 {
    width: 255px;
  }
  .paragraph-4 {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
  .heading-5 {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
  .column-6 {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .caixamsg {
    width: 540px;
  }
  .column-7 {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .column-8 {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .container-6 {
    padding-left: 10px;
  }
  .icon-3 {
    color: #ffa100;
  }
  .column-11 {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .column-12 {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .column-13 {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .container-7 {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  .container-8 {
    display: block;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .column-16 {
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
    border: 1px solid transparent;
  }
  .container-9 {
    padding-right: 10px;
    padding-left: 10px;
  }
  .column-17 {
    margin-top: 25px;
    margin-bottom: 25px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .column-18 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .column-19 {
    margin-top: 25px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .cardhome1 {
    width: auto;
    height: auto;
    padding-right: 0px;
    padding-left: 0px;
  }
  .cardhome2 {
    width: 548px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
  }
  .column-22 {
    margin-bottom: 25px;
  }
  .cardcurso {
    width: 548px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .div-block-31 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .curso {
    width: 448px;
    height: 347px;
  }
  ._222 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 548px;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid transparent;
  }
  .div-block-32 {
    text-align: center;
  }
  .column-23 {
    margin-top: 20px;
  }
  .text-block-14 {
    margin-top: 5px;
    line-height: 20px;
  }
  .text-block-15 {
    margin-right: 0%;
  }
  .paragraph-5 {
    margin-right: 0%;
  }
  .agenda1 {
    padding-top: 35px;
    padding-bottom: 35px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  .column-24 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .column-25 {
    margin-top: 20px;
  }
  .success-message {
    width: 100%;
    max-width: none;
  }
  .error-message {
    width: 100%;
    max-width: none;
  }
  .button-small.form-centered-button {
    width: 100%;
  }
  .container-10 {
    display: block;
  }
  .div-block-37 {
    width: 400px;
    margin-right: auto;
    margin-left: auto;
  }
  .cursopdf-2 {
    width: 448px;
    height: 347px;
  }
  .prova {
    padding-right: 10px;
    padding-left: 10px;
  }
  .div-block-38 {
    margin-right: 10px;
    margin-left: 10px;
  }
  .column-31 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .conquistas {
    margin-right: 20px;
  }
  .column-33 {
    margin-top: 30px;
  }
  .atividades {
    margin-top: 78px;
  }
  .resultado-provas {
    margin-top: 40px;
  }
  .dropdown-list.w--open {
    background-color: #fff;
  }
  .div-block-52 {
    margin-right: 10px;
    margin-left: 10px;
  }
  .premiosadquiridos {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
  }
  .text-block-46 {
    margin-top: 20px;
  }
  .div-block-53 {
    padding-top: 177px;
    padding-bottom: 177px;
  }
  .bloc1 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .column-34 {
    margin-top: 40px;
  }
  .div-block-56 {
    margin-right: 10px;
    margin-left: 10px;
  }
  .column-35 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .column-36 {
    margin-top: 25px;
  }
  .videolive {
    height: 330px;
    margin-right: 10px;
    margin-left: 10px;
    background-position: 0% 50%;
  }
  .div-block-57 {
    margin-right: 0px;
    margin-left: 0px;
  }
  .column-38 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .text-block-50 {
    margin-right: -386px;
    margin-left: 17px;
  }
  .camilafoto {
    margin-left: 5px;
  }
  .div-block-63 {
    padding-bottom: 10px;
  }
  .dropdown-link {
    color: #333;
  }
  .dropdown-link-2 {
    color: #333;
  }
  .blocopdf {
    height: 330px;
  }
  .div-block-68 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .div-block-69 {
    margin-right: auto;
    margin-left: auto;
  }
  .column-41 {
    margin-top: 290px;
    margin-right: auto;
    margin-left: auto;
  }
  .video2 {
    width: auto;
    height: 448px;
  }
  .video-3 {
    width: auto;
    height: 448px;
  }
  .video4 {
    width: auto;
    height: 347px;
  }
  .video5 {
    width: auto;
    height: 347px;
  }
  .video-6 {
    width: auto;
    height: 347px;
  }
  .video7 {
    width: 548px;
    height: 448px;
  }
  .video8 {
    width: 548px;
    height: 448px;
  }
  .video9 {
    width: 548px;
    height: 448px;
  }
  .video10 {
    width: 548px;
    height: 448px;
  }
  .video11 {
    width: 548px;
    height: 448px;
  }
  .video23 {
    width: 548px;
    height: 448px;
  }
  .container-15 {
    padding: 0px 10px;
  }
  .container-16 {
    padding-right: 10px;
    padding-left: 10px;
  }
  .eu {
    padding-right: 0px;
    padding-left: 0px;
  }
  .column-38-2 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .column-42 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .column-43 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .nomebold {
    margin-right: -386px;
    margin-left: 17px;
  }
  .texto-banner-2 {
    margin-right: 10px;
    margin-left: 10px;
    padding-right: 0px;
  }
  .columns-11 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .textobanner2 {
    margin-left: 10px;
    padding-right: 184px;
  }
  .titulo-2 {
    margin-right: 10px;
    margin-bottom: 60px;
    margin-left: 10px;
  }
  .titulo4 {
    margin-right: 10px;
    margin-left: 10px;
  }
  .titulo-5 {
    margin-right: 10px;
    margin-left: 10px;
  }
  .titulo6 {
    margin-right: 10px;
    margin-left: 10px;
  }
  .div-interna-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 448px;
    height: auto;
    border: 1px solid transparent;
  }
  .div-block-74 {
    margin-right: auto;
    margin-left: auto;
  }
  .cardhome2-1 {
    width: 448px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .btnforum {
    width: 220px;
  }
  .column-49 {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
  }
  .trilha1 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .trillha1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .section-4 {
    margin-right: 10px;
    margin-left: 10px;
  }
  .cursopdf-1 {
    width: 448px;
    height: 347px;
  }
  .cursopdf2 {
    width: 448px;
    height: 347px;
  }
  .cursopdf-4 {
    width: 448px;
    height: 347px;
  }
  .cursopdf6 {
    width: 448px;
    height: 347px;
  }
  .cursopdf-7 {
    width: 448px;
    height: 347px;
  }
  .cursopdf-9 {
    width: 448px;
    height: 347px;
  }
  .link-block-4 {
    margin-right: auto;
    margin-left: auto;
  }
  .column-50 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .div-block-89 {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .container-21 {
    padding-right: 20px;
    padding-left: 10px;
  }
  .div-block-90 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .div-block-91 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .div-block-92 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .div-block-93 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .div-block-94 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .div-block-95 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .div-block-96 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .linha-2 {
    position: relative;
    left: 27px;
    right: 0px;
    z-index: 0;
    width: 2px;
    height: 1900px;
    margin: 0px 2px 0px 0px;
    border-color: #d1d1d1;
    background-color: #e4e0e0;
  }
  .div-block-97 {
    display: block;
  }
  .cursopdf-image3 {
    width: 448px;
    height: 347px;
  }
  .cursopdfimage4 {
    width: 448px;
    height: 347px;
  }
  .prova2 {
    padding-right: 10px;
    padding-left: 10px;
  }
  .imagep_2 {
    padding-top: 177px;
    padding-bottom: 177px;
  }
  .imagep_3 {
    padding-top: 177px;
    padding-bottom: 177px;
  }
  .imagep_4 {
    padding-top: 177px;
    padding-bottom: 177px;
  }
  .div-block-99 {
    width: 250px;
  }
  .andamento {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
  }
  .premiosloja {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
  }
  .slide-3 {
    background-position: 0px 0px, 60% 50%;
  }
  .container-24 {
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 56px;
  }
  .heading-6 {
    width: 90%;
  }
  .slide-4 {
    background-position: 0px 0px, 70% 50%;
  }
  .div-block-303 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .categoria {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .div-block-3 {
    margin-right: 10px;
  }
  .accordion-item-content {
    margin-right: 0px;
  }
  .nao_iniciado {
    padding-right: 10px;
    padding-left: 10px;
  }
  .listagem-curso {
    width: 500px;
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
  }
  .section-1 {
    background-color: #fff;
  }
  .white-box {
    margin-bottom: 30px;
  }
  .btn-7 {
    padding-right: 45px;
    padding-left: 45px;
  }
  .row {
    margin-right: auto!important;
    margin-left: auto!important;
  }
  .campoemail {
    padding-top: 3px;
  }
  .btn-email {
    padding-right: 87px;
  }
  .form-news {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 11px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .column-54 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .container-27 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 479px) {
  .nav-menu {
    margin-bottom: 0px;
    padding-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-radius: 16px;
    background-color: #0e0e0e;
  }
  .container {
    display: block;
  }
  .div-block-2 {
    margin-bottom: 0px;
  }
  .heading-2 {
    font-size: 35px;
  }
  .video {
    width: auto;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    background-position: 0px 0px, 50% 30%;
  }
  .recomendados {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
  }
  .texto-interna {
    width: auto;
  }
  .div-interna {
    display: block;
    width: auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .columns {
    margin-right: auto;
    margin-left: auto;
  }
  .titulo {
    margin-right: 10px;
    margin-left: 10px;
  }
  .btn {
    margin-top: 25px;
  }
  .mais-assistidos {
    width: 95%;
    margin-top: -23px;
    margin-right: auto;
    margin-left: auto;
  }
  .video-copy {
    width: auto;
    height: 150px;
  }
  .columns-2 {
    width: 95%;
    margin-top: 41px;
    margin-right: auto;
    margin-left: auto;
  }
  .linha1 {
    margin-left: -57px;
  }
  .linha1._2 {
    margin-right: 90px;
    margin-left: 0px;
    padding-right: 0px;
  }
  .rodap-_2 {
    padding-right: 10px;
    padding-left: 10px;
  }
  .image {
    width: 100px;
    padding-top: 0px;
  }
  .heading-3 {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 106px;
  }
  .div-block-14 {
    display: block;
    width: 95%;
    margin: 0px auto auto;
    padding-right: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .cursos {
    color: #fff;
  }
  .pesquisa-cursos {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .banner2 {
    margin-top: 73px;
  }
  .container-5 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .camila2 {
    padding: 0px 20px 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    color: #fff;
  }
  .camila-icon {
    margin-right: 0px;
    padding-left: 0px;
    color: #fff;
  }
  .div-block-17 {
    height: 180px;
  }
  .material-de-apoio {
    padding-bottom: 0px;
  }
  .blocapoio1 {
    margin-right: 0px;
  }
  .menu-button {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 12px;
    border-radius: 50px;
    background-color: transparent;
    color: #ffa100;
  }
  .menu-button.w--open {
    margin-top: 0px;
    margin-right: 10px;
    padding: 12px;
    border-radius: 50px;
    background-color: rgba(14, 14, 14, 0.07);
    color: #ffa100;
  }
  .linha {
    height: 1200px;
    margin-left: 0px;
    border-color: #333;
  }
  .column {
    padding-top: 115px;
    padding-bottom: 136px;
  }
  .column-2 {
    padding-top: 142px;
    padding-bottom: 135px;
  }
  .column-3 {
    padding-top: 144px;
    padding-bottom: 130px;
  }
  .column-4 {
    padding-top: 144px;
    padding-bottom: 132px;
  }
  .trilhas {
    margin-left: 0px;
  }
  .videos3 {
    margin-top: 0px;
  }
  .text-field {
    width: 300px;
  }
  .text-field-2 {
    width: 300px;
  }
  .div-block-24 {
    margin-top: 40px;
  }
  .paragraph-4 {
    margin-bottom: 0px;
  }
  .link-block {
    width: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .heading-5 {
    margin-top: 10px;
  }
  .column-5 {
    margin-bottom: 0px;
  }
  .column-6 {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .div-block-27 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .caixamsg {
    width: 300px;
  }
  .div-block-29 {
    margin-bottom: 30px;
  }
  .column-7 {
    margin-top: 25px;
    margin-bottom: 25px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .column-8 {
    margin-top: 25px;
    margin-bottom: 25px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .trilhasmenu {
    padding-right: 205px;
    color: #fff;
  }
  .menulive {
    color: #fff;
  }
  .forum {
    color: #fff;
  }
  .bold-text {
    margin-right: 0px;
    padding-right: 0px;
    font-size: 25px;
    line-height: 0px;
  }
  .container-6 {
    padding-right: 10px;
    padding-left: 10px;
  }
  .icon-3 {
    color: #0e0e0e;
  }
  .container-8 {
    display: block;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .column-20 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .form {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cardhome1 {
    width: auto;
    height: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .cardhome2 {
    width: auto;
  }
  .column-22 {
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
  }
  .cardcurso {
    display: block;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
  .curso {
    width: auto;
    height: 150px;
    background-position: 0px 0px, 50% 10%;
    background-size: auto, cover;
  }
  ._222 {
    display: block;
    width: auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .button-small {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .container-10 {
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
  }
  .div-block-37 {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    padding: 18px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cursopdf-2 {
    width: auto;
    height: 150px;
  }
  .prova {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .div-block-38 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  .container-11 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  .text-block-20 {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }
  .column-31 {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .columns-7 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .conquistas {
    margin-right: 10px;
  }
  .jan {
    margin-right: 3px;
  }
  .column-33 {
    margin-top: 45px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .fev {
    margin-right: 3px;
  }
  .mar {
    margin-right: 3px;
  }
  .abr {
    margin-right: 3px;
  }
  .maio {
    margin-right: 3px;
  }
  .jun {
    margin-right: 3px;
  }
  .julho {
    margin-right: 3px;
  }
  .ago {
    margin-right: 3px;
  }
  .set {
    margin-right: 3px;
  }
  .out {
    margin-right: 3px;
  }
  .nov {
    margin-right: 3px;
  }
  .meses {
    margin-top: 0px;
  }
  .atividades {
    display: block;
    margin-top: 57px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .provas-realizadas {
    height: 120px;
    margin-right: 10px;
    padding: 0px 12px;
  }
  .tituloprov {
    margin-bottom: 5px;
  }
  .provas {
    margin-right: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .baixarbtn {
    margin-top: 15px;
    margin-right: 0px;
  }
  .dropdown-toggle {
    padding-top: 20px;
  }
  .dropdown-list.w--open {
    border-color: #ddd;
    background-color: #fff;
  }
  .premiosadquiridos {
    margin-right: 10px;
    margin-left: 10px;
  }
  .div-block-56 {
    margin-right: 0px;
    margin-left: 0px;
  }
  .provas-realizadas-copy {
    height: 120px;
    margin-right: 0px;
  }
  .column-35 {
    margin-top: 25px;
    margin-bottom: 25px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .column-36 {
    margin-top: 25px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .videolive {
    height: 180px;
  }
  .text-block-48 {
    display: none;
    margin-top: 15px;
    margin-right: 0px;
    margin-left: 6px;
  }
  .div-block-57 {
    margin-right: 0px;
  }
  .column-37 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .column-38 {
    padding-right: 155px;
    padding-left: 0px;
  }
  .text-block-50 {
    margin-right: -410px;
    margin-left: 16px;
  }
  .colocado1 {
    margin-right: -68px;
    padding-top: 6px;
    padding-bottom: 6px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .text-block-51 {
    margin-right: 10px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .prata {
    display: none;
    margin-top: 12px;
    margin-right: 0px;
  }
  .colocado2 {
    padding-right: 0px;
    padding-left: 11px;
  }
  .bronze {
    display: none;
    margin-top: 15px;
    margin-right: 0px;
  }
  .colocado3 {
    padding-right: 0px;
    padding-left: 11px;
  }
  .colocado4 {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .outros {
    display: none;
    margin-top: 11px;
    margin-right: 0px;
    margin-left: 0px;
  }
  .camilafoto {
    width: 30px;
    height: 30px;
    margin-left: 0px;
  }
  .colocado22 {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .div-block-63 {
    background-position: 0px 0px, 50% 30%;
  }
  .btncertificado {
    margin-top: 15px;
    margin-right: 0px;
  }
  .dropdown-link {
    color: #333;
  }
  .dropdown-link.w--current {
    color: #ffa100;
  }
  .dropdown-link-2 {
    color: #333;
  }
  .btnhome {
    padding-right: 14px;
    padding-left: 14px;
  }
  .blocopdf {
    height: 180px;
  }
  .text-block-58 {
    font-size: 50px;
  }
  .text-block-59 {
    font-size: 14px;
  }
  .btnconquistas {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .column-40 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .div-block-69 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 95%;
    padding-bottom: 55px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  .column-41 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .video2 {
    width: auto;
    height: auto;
  }
  .video-3 {
    width: auto;
    height: auto;
  }
  .video4 {
    width: auto;
    height: 150px;
  }
  .video5 {
    width: auto;
    height: auto;
  }
  .video-6 {
    width: auto;
    height: auto;
  }
  .video7 {
    width: auto;
    height: 160px;
  }
  .video8 {
    width: auto;
    height: 160px;
  }
  .video9 {
    width: auto;
    height: 160px;
  }
  .video10 {
    width: auto;
    height: 160px;
  }
  .video11 {
    width: auto;
    height: 160px;
  }
  .video23 {
    width: auto;
    height: 160px;
  }
  .container-16 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .eu {
    padding-right: 155px;
    padding-left: 0px;
  }
  .section-3 {
    padding-right: 10px;
    padding-left: 10px;
  }
  .column-38-2 {
    padding-right: 156px;
    padding-left: 0px;
  }
  .column-44 {
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
  }
  .container-17 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
  }
  .column-45 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .column-46 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .nomebold {
    margin-right: -410px;
    margin-left: 16px;
  }
  .texto-banner-2 {
    display: block;
    width: 95%;
    margin: 15px auto auto;
    padding-right: 108px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .columns-11 {
    margin-right: auto;
    padding-right: 0px;
    padding-left: 15px;
  }
  .image-4 {
    margin-right: auto;
    margin-left: auto;
  }
  .div-block-72 {
    margin-left: 0px;
  }
  .textobanner2 {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    font-size: 30px;
  }
  .container-19 {
    padding-right: 5px;
    padding-left: 5px;
  }
  .column-47 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .titulo-2 {
    margin-right: 10px;
    margin-left: 10px;
  }
  .titulo4 {
    margin-right: 10px;
    margin-left: 10px;
  }
  .titulo-5 {
    margin-right: 10px;
    margin-left: 10px;
  }
  .titulo6 {
    margin-right: 10px;
    margin-left: 10px;
  }
  .div-interna-copy {
    display: block;
    width: auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .div-block-74 {
    margin-right: auto;
    margin-left: auto;
  }
  .cardhome2-1 {
    width: auto;
  }
  .text-block-71 {
    height: 20px;
    margin-right: 0px;
  }
  .geral1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .trillha1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .text-block-73 {
    line-height: 30px;
    text-align: center;
  }
  .div-block-84 {
    margin-bottom: 20px;
  }
  .cursopdf-1 {
    width: auto;
    height: 150px;
  }
  .cursopdf2 {
    width: auto;
    height: 150px;
  }
  .cursopdf-4 {
    width: auto;
    height: 150px;
  }
  .cursopdf6 {
    width: auto;
    height: 150px;
  }
  .cursopdf-7 {
    width: auto;
    height: 150px;
  }
  .cursopdf-9 {
    width: auto;
    height: 150px;
  }
  .nomelink {
    margin-left: 10px;
  }
  .column-50 {
    margin-top: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .div-block-89 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .container-21 {
    margin-top: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .div-block-93 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .div-block-94 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .div-block-95 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .div-block-96 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .linha-2 {
    height: 1200px;
    margin-left: 0px;
    border-color: #333;
  }
  .image_2 {
    margin-left: 15px;
  }
  .image_1 {
    margin-left: 15px;
  }
  .image_3 {
    margin-left: 15px;
  }
  .image_4 {
    margin-left: 15px;
  }
  .image_5 {
    margin-left: 15px;
  }
  .cursopdf-image3 {
    width: auto;
    height: 150px;
  }
  .cursopdfimage4 {
    width: auto;
    height: 150px;
  }
  .prova2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .banner2trilhas {
    margin-top: 73px;
  }
  .div-block-98 {
    display: none;
  }
  .div-block-99 {
    width: 150px;
  }
  .div-block-100 {
    margin-bottom: 15px;
  }
  .grafico2 {
    width: 50px;
  }
  .div-block-301 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .slide-3 {
    background-position: 0px 0px, 60% 50%;
  }
  .container-24 {
    margin-top: 0px;
    padding-left: 46px;
  }
  .text-block-77 {
    width: auto;
  }
  .slide-4 {
    padding-top: 0px;
    background-position: 0px 0px, 65% 50px;
    background-size: auto, cover;
  }
  .column-51 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .column-52 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .div-block-303 {
    margin-top: 0px;
  }
  .div-block-305 {
    margin-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .div-block-306 {
    width: 125px;
  }
  .text-block-78 {
    font-size: 10px;
  }
  .div-block-307 {
    margin-right: 25px;
    margin-left: 25px;
  }
  .text-block-79 {
    font-size: 10px;
  }
  .linhaforum {
    width: 300px;
  }
  .search-2 {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    color: #fff;
  }
  .div-block-3 {
    margin-right: 10px;
  }
  .search-input-2 {
    display: none;
  }
  .search-button-2 {
    padding-left: 0px;
    color: #fff;
  }
  .div-block-311 {
    margin-left: 0px;
  }
  .div-block-312 {
    margin-top: 0px;
  }
  .text-block-81 {
    margin-top: 5px;
  }
  .text-block-82 {
    margin-top: 5px;
  }
  .text-block-83 {
    margin-top: 5px;
  }
  .text-block-84 {
    margin-top: 5px;
  }
  .em-andamento {
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .accordion-item-trigger {
    padding-right: 10px;
    padding-left: 10px;
  }
  .div-block-18-trajetoria {
    padding-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .nao_iniciado {
    width: auto;
    margin-right: 5px;
    margin-left: 5px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .listagem-curso {
    position: static;
    width: 103%;
    margin: 0px auto 15px;
    padding-top: 10px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .andamento_foto_1 {
    width: 60px;
    height: 40px;
    margin-left: 0px;
  }
  .nomelink-2 {
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 15px;
  }
  .andamento_foto_2 {
    width: 60px;
    height: 40px;
    margin-left: 5px;
  }
  .andamento_foto_3 {
    width: 60px;
    height: 40px;
    margin-left: 5px;
  }
  .andamento_foto_4 {
    width: 60px;
    height: 40px;
    margin-left: 5px;
  }
  .container-25 {
    padding-right: 5px;
    padding-left: 0px;
  }
  .dropdown-wrapper {
    padding-right: 15px;
    padding-bottom: 0px;
    padding-left: 15px;
  }
  .section-1 {
    display: block;
    padding-right: 4vh;
    padding-left: 4vh;
  }
  .section-1.full.custompaddings {
    padding: 0vh 3vw;
    background-color: #f4f4f6;
  }
  .service-title {
    font-size: 1.1em;
  }
  .input-label {
    font-size: 1em;
  }
  .wrapper-simple.margins-40.dropdown-toggle {
    padding-right: 20px;
    padding-left: 20px;
  }
  .wrapper-flex-horizontal.align-left {
    padding-left: 10px;
  }
  .wrapper-flex-horizontal.align-just {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-grid-columns: 1fr 4.25fr;
    grid-template-columns: 1fr 4.25fr;
  }
  .checkbox-field {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .heading-8 {
    font-size: 35px;
  }
  .progress-bar.color.progress-zero {
    width: 0%;
  }
  .progress-bar.white.long-bar {
    width: 170px;
    min-width: 180px;
    margin-right: 10px;
  }
  .logo.no-margins {
    display: none;
  }
  .service-title-2 {
    font-size: 1.1em;
  }
  .btn-7 {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
  .btn-7.nav {
    width: 90%;
    margin-left: 15px;
  }
  .fixed-submit-bar-2 {
    padding-bottom: 1.4em;
  }
  .progress-bar-copy.white.long-bar {
    width: 170px;
    min-width: 180px;
    margin-right: 10px;
  }

  .campoemail {
    border-radius: 12px;
  }
  .btn-email {
    width: 100%;
    margin-top: 10%;
    padding-right: 84px;
    padding-left: 16px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    text-align: center;
  }
  .form-news {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .column-53 {
    text-align: center;
  }
  .bloco-modal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    padding-right: 10px;
    padding-left: 10px;
  }
  .email {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .column-54 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 10%;
    padding-left: 10%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .text-block-87 {
    text-align: center;
  }
  .progress-bar-maior.white.long-bar {
    width: 170px;
    min-width: 180px;
    margin-right: 10px;
  }
  .btnhome-copy {
    padding-right: 14px;
    padding-left: 14px;
  }
  .radio-button-8 {
    width: 17px;
    margin-top: -3px;
    margin-left: -20px;
  }
}

#w-node-ac098ba86490-e1bdef4e {
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
}

#w-node-ac098ba86494-e1bdef4e {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-ac098ba8649d-e1bdef4e {
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

@font-face {
  font-family: 'Font awesome 5 brands 400';
  src: url('../fonts/Font-Awesome-5-Brands-Regular-400.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Font awesome 5 pro 300';
  src: url('../fonts/Font-Awesome-5-Pro-Light-300.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Font awesome 5 pro solid 900';
  src: url('../fonts/Font-Awesome-5-Pro-Solid-900.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Font awesome 5 pro 400';
  src: url('../fonts/Font-Awesome-5-Pro-Regular-400.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}


